import React, { useState, useContext, useEffect, useRef } from "react";
import {
  TextField,
  IconButton,
  Typography,
  Grid,
  Button,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getSello } from "../Demanda/DemandaService";
import RegistrosAntiguosForm from "./RegistrosAntiguosForm";
import KeycloakConf from "../../api/keycloakConf";
import { useLocation, useNavigate } from "react-router-dom";
import { validateFields } from "../../components/Alert/ValidationRequired/validationRequired";
import {
  onlyNames,
  onlyAlphaNumericSpace,
} from "../../components/Caracteres/caracteresEspeciales";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { useToastAlert } from "../../components/Alert/ToastAlertSave/ToastAlertSave";
import { useToastAlertValidation } from "../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import GenerarDemanda from "../Demanda/Form/GenerarDemanda";
// Formularios
import Actor from "../Demanda/Form/RegistrosDemanda/Actor";
import Demandado from "../Demanda/Form/RegistrosDemanda/Demandado";
import Anexo from "../Demanda/Form/RegistrosDemanda/Anexo";
// Utils
import { crearActor, crearDemandado } from "../Demanda/Form/RegistrosDemanda/DemandaUtils/documentoUtils";
// States
import {
  initialActorFisica,
  initialActorMoral,
  initialDemandadoFisica,
  initialDemandadoMoral,
} from "../Demanda/Form/RegistrosDemanda/DemandaUtils/initialStates";
import DigitalizarDemanda from "./DigitalizarDemanda";
import { createDemandaAntigua } from "../Demanda/DemandaService";



export default function RegistrosAntiguos({
  isEditable,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const { juicioId, nombreJuicio, materia } = location.state || {};
  const anexoRefs = useRef([]);
  const [anexosErrors, setAnexosErrors] = useState([]);

  const [actorTab, setActorTab] = useState(0);
  const [demandadoTab, setDemandadoTab] = useState(0);
  const [anexos, setAnexos] = useState([]);
  const [tipoJuicioId, setTipoJuicioId] = useState(juicioId);
  const { getToken } = useContext(KeycloakConf);
  const [modalOpen, setModalOpen] = useState(false);
  const [razonEdicion, setRazonEdicion] = useState("");
  const [isEditAnexos, setIsEditAnexos] = useState(false);

  const [isError, setIsError] = useState(true);
  const [file, setFile] = useState(null);  // Estado para manejar el archivo cargado
  const handleError = (errorStatus) => {
    // Aquí se puede hacer lo que necesites con el valor true
    setIsError(errorStatus);
    console.log(setIsError(errorStatus))
  };
  const handleFileSelect = (selectedFile) => {
    setFile(selectedFile);  // Guardamos el archivo en el estado
  }

  const [fechaHoraActual, setFechaHoraActual] = useState({
    fecha: "",
    hora: "",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setFechaHoraActual({
        fecha: now.toLocaleDateString(),
        hora: now.toLocaleTimeString(),
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const [actorFisica, setActorFisica] = useState(initialActorFisica);
  const [actorMoral, setActorMoral] = useState(initialActorMoral);
  const [demandadoFisica, setDemandadoFisica] = useState(initialDemandadoFisica);
  const [demandadoMoral, setDemandadoMoral] = useState(initialDemandadoMoral);

  const [formData, setFormData] = useState(null);
  const [errors, setErrors] = useState({});
  const handleActorToggleChange = (event, newValue) => {
    if (newValue !== null) {
      setActorTab(newValue);
    }
  };

  const handleFormSubmit = (data) => {
    setFormData(data);
  };

  const handleActorTabChange = (event, newValue) => {
    setActorTab(newValue);
  };

  const handleDemandadoTabChange = (event, newValue) => {
    setDemandadoTab(newValue);
  };

  const handleAddAnexo = () => {

    if (!isError) {
      setAnexos([...anexos, ""]);

      setTimeout(() => {
        const lastAnexoRef = anexoRefs.current[anexos.length];
        if (lastAnexoRef) lastAnexoRef.focus();
      }, 0);

    }

  };

  const handleAnexoChange = (index, value) => {
    const newValue = onlyAlphaNumericSpace(value);
    const newAnexos = [...anexos];
    newAnexos[index] = newValue;
    setAnexos(newAnexos);

    const newAnexosErrors = [...anexosErrors];
    newAnexosErrors[index] =
      value.length >= 3
        ? ""
        : `El anexo ${index + 1} debe tener al menos 3 caracteres.`;
    setAnexosErrors(newAnexosErrors);
  };

  const fieldsMap = {
    nombreActor: onlyNames,
    apellidoPaternoActor: onlyNames,
    apellidoMaternoActor: onlyNames,
    pseudonimoActor: onlyNames,
    nombreActorMoral: onlyNames,
    nombreDemandado: onlyNames,
    apellidoPaternoDemandado: onlyNames,
    apellidoMaternoDemandado: onlyNames,
    pseudonimoDemandado: onlyNames,
    nombreDemandadoMoral: onlyNames,
  };

  const handleInputChange = (e, setStateFunction) => {
    let { name, value } = e.target;

    const caracterValue = (fieldsMap[name] || ((val) => val))(value);

    setStateFunction((prevState) => ({
      ...prevState,
      [name]: caracterValue,
    }));

    if (caracterValue.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const validateForm = () => {
    const actorRules =
      actorTab === 0
        ? {
          nombreActor: { required: true },
          apellidoPaternoActor: { required: true },
        }
        : {
          nombreActorMoral: { required: true },
        };

    const demandadoRules =
      demandadoTab === 0
        ? {
          nombreDemandado: { required: true },
          apellidoPaternoDemandado: { required: true },
        }
        : {
          nombreDemandadoMoral: { required: true },
        };

    const actorErrors = validateFields(
      actorTab === 0 ? actorFisica : actorMoral,
      actorRules,
      showAlertValidate
    );
    const demandadoErrors = validateFields(
      demandadoTab === 0 ? demandadoFisica : demandadoMoral,
      demandadoRules,
      showAlertValidate
    );

    setErrors({
      ...actorErrors,
      ...demandadoErrors,
    });

    return (
      Object.keys(actorErrors).length === 0 &&
      Object.keys(demandadoErrors).length === 0
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const newAnexosErrors = anexos.map((anexo, index) =>
      anexo.length < 3 ? index + 1 : ""
    );
    setAnexosErrors(newAnexosErrors);

    const errors = newAnexosErrors.filter((error) => error !== "");
    if (errors.length === 1) {
      const errorMessage = `El anexo ${errors.join(
        ", "
      )} debe tener al menos 3 caracteres.`;
      showSnackbar(errorMessage, "error");
      return;
    } else if (errors.length > 0) {
      const errorMessage = `Los anexos ${errors.join(
        ", "
      )} deben tener al menos 3 caracteres.`;
      showSnackbar(errorMessage, "error");
      return;
    }

    setIsEditAnexos(false);
    setModalOpen(true);
  };

  const handleSubmitEdicion = () => {
    if (!validateForm()) return;

    setIsEditAnexos(true);
    setModalOpen(true);
  };



  const handleButtonClick = (e) => {
    e.preventDefault();
    if (!isError) {
      handleSubmit(e);
    } else {
      showSnackbar("Por favor, carga el archivo antes de continuar", "error");
    }
  };


  const handleConfirm = async () => {
    const token = await getToken();

    // datos para el registro
    const nuevoDocumento = {
      actor: crearActor(actorTab, actorFisica, actorMoral),
      demandado: crearDemandado(demandadoTab, demandadoFisica, demandadoMoral),
      anexos: anexos,
      numero: formData.numeroExpediente,
      anio: formData.anioExpediente,
    };

    if (!file) {
      showSnackbar("Por favor, carga el archivo antes de continuar", "error");
      return;
    }
    try {
      let documento = await createDemandaAntigua(token, nuevoDocumento, file);

      showSnackbar("Demanda generada con éxito", "success");
      setActorFisica(initialActorFisica);
      setActorMoral(initialActorMoral);
      setDemandadoFisica(initialDemandadoFisica);
      setDemandadoMoral(initialDemandadoMoral);
      setAnexos([]);
      setFormData(null);
      setFile(null);
      setActorTab(0);
      setDemandadoTab(0);
      const sello = await getSello(token, documento.id);
      var blob = new Blob([sello], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "sello.pdf";
      link.click();
    } catch (error) {
      if (error.response && error.response.data[0]) {
        showSnackbar(error.response.data[0].message, "error");
      } else {
        showSnackbar("¡Algo salió mal!", "error");
      }
    }

    setModalOpen(false);
  };

  const handleClose = () => {
    navigate("/home");
  };

  const handleRemoveAnexo = (index) => {
    const newAnexos = anexos.filter((_, i) => i !== index);
    setAnexos(newAnexos);
  };
 
  return (
    <>
      <GenerarDemanda
        handleButtonClick={handleButtonClick}
        handleClose={handleClose}
        fechaHoraActual={fechaHoraActual}
      />
      <Box className="box-sombra">

        <RegistrosAntiguosForm onSubmit={handleFormSubmit} onError={handleError} />

       
        <ConfirmationModal
          open={modalOpen && !isEditAnexos}
          onClose={() => setModalOpen(false)}
          onConfirm={handleConfirm}
          title="¿Generar demanda?"
          message="Se generará una nueva demanda en el sistema."
          confirmationButton="Sí, generar"
          closeButton="No, cancelar"
        />

        <>
          <form onSubmit={handleSubmit}>
           
            {!isError ? (  <Grid sx={{ mt: 4 }} >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={1}>
                  <Typography>Actor: </Typography>
                </Grid>

                <Grid item xs={4} mb={2} ml={7}>
                  <ToggleButtonGroup
                    value={actorTab}
                    color="primary"
                    exclusive
                    onChange={handleActorToggleChange}
                    fullWidth
                  >
                    <ToggleButton value={0}>Persona física</ToggleButton>
                    <ToggleButton value={1}>Persona moral</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>

              {/* FORM ACTOR */}
              <Actor
                actorTab={actorTab}
                actorFisica={actorFisica}
                actorMoral={actorMoral}
                handleInputChange={handleInputChange}
                setActorFisica={setActorFisica}
                setActorMoral={setActorMoral}
                errors={errors}
                isEditable={isError}
              />
              {/* FORM DEMANDADO */}
              <Divider sx={{ my: 5 }} />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={1}>
                  <Typography>Demandado: </Typography>
                </Grid>

                <Grid item xs={4} mb={2} ml={7}>
                  <ToggleButtonGroup
                    value={demandadoTab}
                    color="primary"
                    exclusive
                    onChange={handleDemandadoTabChange}
                    fullWidth
                  >
                    <ToggleButton value={0}>Persona física</ToggleButton>
                    <ToggleButton value={1}>Persona moral</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>

              <Demandado
                demandadoTab={demandadoTab}
                demandadoFisica={demandadoFisica}
                demandadoMoral={demandadoMoral}
                handleInputChange={handleInputChange}
                setDemandadoFisica={setDemandadoFisica}
                setDemandadoMoral={setDemandadoMoral}
                errors={errors}
                isEditable={isError}
              />



              <Divider sx={{ my: 5 }} />
              {/*  */}

              {/* ANEXOS */}
              <Grid container spacing={12}>
                <Grid item xs={12} sm={1} mt={2}>
                  <Typography>Anexos: </Typography>
                </Grid>


                <Anexo
                  anexos={anexos}
                  handleAnexoChange={handleAnexoChange}
                  handleRemoveAnexo={handleRemoveAnexo}
                  handleAddAnexo={handleAddAnexo}
                  anexosErrors={anexosErrors}
                  anexoRefs={anexoRefs}
                />

              </Grid>

              <Divider sx={{ my: 5 }} />


              <Grid container spacing={12}>

                <Grid item xs={12} sm={1} mt={2}>Documento: </Grid>
                <DigitalizarDemanda onFileSelect={handleFileSelect} />
              </Grid>
            </Grid>) : (<Grid></Grid>)}
          
          </form>
        </>
      </Box>
    </>
  );

}
