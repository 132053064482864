import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, Card, CardContent, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Modal, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useToastAlertValidation } from '../Alert/ToastAlertValidation/ToastAlertValidation';
import { useToastAlert } from '../Alert/ToastAlertSave/ToastAlertSave';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'white',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
};

const DigitalizarAcuseModal = ({ isOpen, handleClose, formData, fileInputRefAcuse, handleSaveAcuseFile, errors }) => {
    const { showSnackbar } = useToastAlert();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const [formDataTemp, setFormDataTemp] = useState({
        acuseFile: formData.acuseFile,
        estatusAcuse: formData.estatusAcuse,
        fechaEntregaOficio: formData.fechaEntregaOficio,
        acuseComentario: formData.acuseComentario
    });
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        if (file.size > 50 * 1024 * 1024) {
            showSnackbar('El archivo debe ser menor a 50 MB', 'error');
            return;
        }
        if (file.type !== "application/pdf") {
            showSnackbar("Solo se permiten archivos PDF.", "error");
            return;
        }

        setFormDataTemp((prevState) => ({
            ...prevState,
            acuseFile: file
        }));

    };

    const handlePreviewFile = (event) => {
        const file = formDataTemp.acuseFile;
        let blob = new Blob([file], { type: 'application/pdf' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob)
        link.target = "acuse.pdf"
        link.click()
    }

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Box sx={style}>
                <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Digitalizar acuse
                </Typography>

                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            fullWidth
                        >
                            Cargar acuse PDF
                            <input
                                accept="application/pdf"
                                type="file"
                                hidden
                                onChange={handleFileUpload}
                                ref={fileInputRefAcuse}
                            />
                        </Button>
                        {errors.acuseFile && (
                            <Typography color="error" variant="body2">
                                {errors.acuseFile}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item xs={0} sm={2}></Grid>

                    {formDataTemp.acuseFile && (
                        <Grid item xs={12} sm={4}>
                            <Card sx={{ maxWidth: 130, position: 'relative', margin: '0 auto' }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        position: 'absolute',
                                        top: 4,
                                        right: 4,
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        color: 'red',
                                    }}
                                    onClick={() => {
                                        setFormDataTemp((prevState) => ({
                                            ...prevState,
                                            acuseFile: ""
                                        }));

                                        if (fileInputRefAcuse.current) {
                                            fileInputRefAcuse.current.value = null;
                                        }
                                    }

                                    }
                                >
                                    X
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 80,
                                    }}
                                >
                                    <PictureAsPdfIcon sx={{ fontSize: 50, color: 'black' }} />
                                </Box>
                                <CardContent sx={{ textAlign: 'center', padding: 1 }}>

                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "blue",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                            "&:hover": {
                                                textDecoration: "none", 
                                            },
                                        }}
                                        onClick={handlePreviewFile}
                                    >
                                        acuse.pdf
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}

                </Grid>

                <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                    <Divider />
                </Box>

                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset" fullWidth error={!!errors.estatusAcuse}>
                            <FormLabel component="legend">¿Se entregó el oficio?</FormLabel>
                            <RadioGroup
                                row
                                value={(formDataTemp.estatusAcuse === 'CREADO' ? "" : formDataTemp.estatusAcuse) || ""}
                                onChange={(e) => {
                                    setFormDataTemp((prevState) => ({
                                        ...prevState,
                                        estatusAcuse: e.target.value
                                    }));
                                }}
                            >
                                <FormControlLabel value="ENTREGADO" control={<Radio />} label="Sí" />
                                <FormControlLabel value="NOENTREGADO" control={<Radio />} label="No" />
                            </RadioGroup>
                            {errors.estatusAcuse && (
                                <FormHelperText>{errors.estatusAcuse}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Fecha de Entrega"
                            type="date"
                            value={formDataTemp.fechaEntregaOficio || ""}
                            error={!!errors.fechaEntregaOficio}
                            helperText={errors.fechaEntregaOficio}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                setFormDataTemp((prevState) => ({
                                    ...prevState,
                                    fechaEntregaOficio: e.target.value
                                }));
                            }}
                            fullWidth
                        />


                    </Grid>
                </Grid>


                <TextField
                    label="Comentario"
                    multiline
                    rows={3}
                    fullWidth
                    value={formDataTemp.acuseComentario || ""}
                    error={!!errors.acuseComentario} helperText={errors.acuseComentario}
                    onChange={(e) => {

                        setFormDataTemp((prevState) => ({
                            ...prevState,
                            acuseComentario: e.target.value
                        }));
                    }}
                    sx={{ mb: 3 }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" className="button-close" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="contained" className="button-save" style={{ marginLeft: '10px' }} onClick={() => {
                        handleSaveAcuseFile(formDataTemp);
                    }}>
                        Guardar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DigitalizarAcuseModal;