
import React, { useState } from 'react';
import { Button, MenuItem, Select, FormControl, InputLabel, Grid, Typography, Box } from '@mui/material';
import CrearLista from '../ModalesGrid/CrearLista';

export default function NotificacionFiltros({ setTipo, setEstadoNotificacion, isButtonEnabled, cantidadNotificaciones, selectedIds, loadNotificacionData }) {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCreateList = () => {
    setIsModalOpen(true); // Abre el modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Cierra el modal
  };

  const handleSaveList = (selectedIds) => {

    setIsModalOpen(false);
  };


  return (
    <>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">

        <Grid item>
          <Box display="flex" alignItems="center">
            <Typography sx={{ marginRight: 1 }}>Tipo:</Typography>
            <FormControl sx={{ width: 300, marginRight: 5 }}>
              <InputLabel>TIPO</InputLabel>
              <Select
                defaultValue="ESTRADO"
                onChange={(e) => setTipo(e.target.value)}
                label="TIPO"
              >
                <MenuItem value="ESTRADO">Estrado</MenuItem>
                <MenuItem value="CORREO_ELECTRONICO">Correo electronico</MenuItem>
                <MenuItem value="DOMICILIO">Domicilio</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="center">
            <Typography sx={{ marginRight: 1 }}>Ver:</Typography>
            <FormControl sx={{ width: 300, marginRight: 5 }}>
              <InputLabel>Ver</InputLabel>
              <Select
                defaultValue="PENDIENTE_DE_ASIGNAR"
                onChange={(e) => setEstadoNotificacion(e.target.value)}
                label="Ver"
              >
                <MenuItem value="PENDIENTE_DE_ASIGNAR">Pendiente de asignar</MenuItem>
                <MenuItem value="ASIGNADO">Asignado</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item>
          <Button
            type="button"
            variant="contained"
            className="button-save"
            onClick={handleCreateList}
            disabled={!isButtonEnabled}
          >
            Crear lista
          </Button>
        </Grid>
      </Grid>
      <CrearLista
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        cantidadNotificaciones={cantidadNotificaciones}
        handleSave={handleSaveList}
        selectedIds={selectedIds}
        loadNotificacionData={loadNotificacionData}
      />
    </>
  );
}
