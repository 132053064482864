import React, { useContext, useEffect, useState } from "react";
import {TextField, Button, MenuItem, Select, FormControl, InputLabel, Grid, Typography, Divider, FormHelperText, Autocomplete } from "@mui/material";
import { validateFields } from "../../../components/Alert/ValidationRequired/validationRequired";
import { useToastAlertValidation } from "../../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import { fetchCarpetaActorDemandado } from "../../../shared/services/CarpetaService";
import keycloakConf from "../../../api/keycloakConf";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { fetchJuzgadosAutocomplete } from "../../../shared/services/JuzgadosService";
import Acciones from "../../../components/Acciones/Acciones";
import { createAudiencia, getSalasAll, getTipoAudiencias, reprogramarAudiencia } from "../BandejaAudienciasService";
import { useLocation, useNavigate } from "react-router-dom";

const ReprogramarAudiencia = () => {
  const { showSnackbar } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const [errorMessages, setErrorMessages] = useState({});
  const [juzgados, setJuzgados] = useState([]);
  const [salas, setSalas] = useState([]);
  const [audiencias, setTipoAudiencias] = useState([]);
  const { getToken } = useContext(keycloakConf);
  const location = useLocation();
  const navigate = useNavigate();
  const { item } = location.state || {};
  const isTab = location.state?.isTab || false;
  const { itemAsignado } = location.state|| false;
  const [minFecha, setMinFecha] = useState("");

  const [formData, setFormData] = useState({
    expediente: "",
    year: "",
    juzgado: "",
    tipo: "",
    sala: "",
    fecha: null,
    hora: null,
    duracion: "",
    descripcion: "",
    audienciaId: ""
  });

  const duraciones = [
    { id: 15, label: "15 minutos" },
    { id: 30, label: "30 minutos" },
    { id: 45, label: "45 minutos" },
    { id: 60, label: "1 hora" },
    { id: 75, label: "1 hora y 15 minutos" },
    { id: 90, label: "1 hora y 30 minutos" },
    { id: 105, label: "1 hora y 45 minutos" },
    { id: 120, label: "2 horas" },
    { id: 135, label: "2 horas y 15 minutos" },
    { id: 150, label: "2 horas y 30 minutos" },
    { id: 165, label: "2 horas y 45 minutos" },
    { id: 180, label: "3 horas" },
  ];

  const [carpeta, setCarpeta] = useState({
    idCarpeta: 0,
    actor: "",
    demandado: "",
  });

  useEffect(() => {
    if (item && item.fechaHora) {
      const fechaBase = item.fechaHora.split("T")[0];
      setMinFecha(fechaBase);
    }
  }, [item])

  useEffect(() => {
    loadData(
      () => fetchJuzgadosAutocomplete(getToken(), 0, 10, "", ""),
      setJuzgados,
      "Juzgados"
    );
    loadData(
      () => getTipoAudiencias(getToken()),
      setTipoAudiencias,
      "Audiencias"
    );
    loadData(() => getSalasAll(getToken()), setSalas, "Salas");
  }, []);

  useEffect(() => {
    if (juzgados.length == 1 && item && !formData.juzgado) {
      asignarData();
    }
    if (item && formData.juzgado && formData.expediente && formData.year) {
      handleValidate(false);
    }
  }, [juzgados, formData.juzgado]);

  useEffect(() => {
    if (item) {
      const tipoAudiencia = audiencias.find(
        (audiencia) => audiencia.nombre === item.tipoAudiencia
      );
      setFormData((prevData) => ({
        ...prevData,
        expediente: item.numCarpeta.split("/")[0],
        year: item.numCarpeta.split("/")[1],
        juzgado: juzgados.find((juzgado) => juzgado.id === item.juzgado),
        tipo: tipoAudiencia ? tipoAudiencia.id : "",
        sala: item.lugar,
        fecha: item.fechaHora ? item.fechaHora.split("T")[0] : "",
        hora: item.fechaHora ? item.fechaHora.split("T")[1].substring(0, 5) : "",
        audienciaId: item.id,
      }));
    }
  }, [item, juzgados, audiencias]);

  const asignarData = () => {
    if (item && item.expediente) {
      let arrayExpediente = item.expediente.split("/");

      setFormData((prevData) => ({
        ...prevData,
        expediente: arrayExpediente[0],
        year: arrayExpediente[1],
        juzgado: juzgados[0],
      }));
    } else {
      console.error("El expediente no está definido");
    }
  };

  const loadData = async (
    fetchFunction,
    setFieldState,
    typeData,
    funfinally = false
  ) => {
    try {
      const response = await fetchFunction();

      await setFieldState(response || []);
    } catch (error) {
      console.error(`Error al obtener los ${typeData}`, error);
    } finally {
      if (funfinally) {
        funfinally();
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleJuzgadoChange = (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      juzgado: newValue,
    }));
  };

  const handleValidate = async (imprimirMensaje = true) => {
    const token = getToken();

    let rulesExpediente = {
      expediente: { required: true },
      year: { required: true },
      juzgado: { required: true },
    };

    const errores = validateFields(
      formData,
      rulesExpediente,
      showAlertValidate
    );

    setErrorMessages(errores);

    if (Object.keys(errores).length === 0) {
      const data = {
        numExpediente: formData.expediente,
        year: formData.year,
        idJuzgado: formData.juzgado.id,
      };

      try {
        const response = await fetchCarpetaActorDemandado(token, data);
        setCarpeta({
          idCarpeta: response.idCarpeta,
          actor: response.actor,
          demandado: response.demandado,
        });

        if (imprimirMensaje) {
          showAlertValidate("Expediente encontrado", "success", "Validación exitosa");
        }
      } catch (error) {
        setCarpeta({});
        showAlertValidate("Expediente inexistente.", "error", "Error en la validación");
      }
    }
  };

  const handleCancel = () => {
    isTab ? navigate('/api/bandeja/expediente', {
      state: {
          item: itemAsignado,
          tabnav: 3
      }
  }) : navigate('/api/bandeja/audiencias');
  };

  const handleReprogramarAudiencia = async () => {
    let rules = {
      expediente: { required: true },
      year: { required: true },
      juzgado: { required: true },
      tipo: { required: true },
      sala: { required: true },
      fecha: { required: true },
      hora: { required: true },
      duracion: { required: true },
      descripcion: { required: true },
    };

    const errores = validateFields(formData, rules, showAlertValidate);

    setErrorMessages(errores);
    if (Object.keys(errores).length === 0) {
      const dataSend = {
        audienciaId: formData.audienciaId,
        carpetaId: carpeta.idCarpeta,
        tipoAudiencia: formData.tipo,
        salaId: formData.sala,
        fecha: formData.fecha,
        hora: formData.hora,
        duracion: formData.duracion,
        descripcion: formData.descripcion,
      };

      try {
        const response = await reprogramarAudiencia(getToken(), dataSend);

        if (response.audienciaId) {
          showAlertValidate("Audiencia reprogramada exitosamente.", "success", "Audiencia reprogramada");
          handleCancel();
        }
      } catch (error) {
        showAlertValidate("Ocurrio un error.", "error", "Error al reprogramar la audiencia.");
        console.log("Ocurrio un error", error);
      }
    }
  };

  const handleTipoChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      tipo: value,
    }));
  };

  const handleSalaChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      sala: value,
    }));
  };

  return (
    <>
      <Acciones accion1={handleReprogramarAudiencia} accion2={handleCancel} textoBoton1="Reprogramar" />
      <Grid container spacing={2} sx={{ marginTop: 1, marginBottom: 2 }}>
        <Grid item xs={12} sm={2} md={2}>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="expediente"
              label="Expediente"
              variant="outlined"
              fullWidth
              value={formData?.expediente || ""}
              disabled={item != null}
              onChange={handleChange}
              error={!!errorMessages.expediente}
              helperText={errorMessages.expediente}
              onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9a-zA-Z]/g, ""); }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2} md={2}>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="year"
              label="Año"
              variant="outlined"
              disabled={item != null}
              fullWidth
              value={formData?.year || ""}
              onChange={handleChange}
              error={!!errorMessages.year}
              helperText={errorMessages.year}
              onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ""); }}
              inputProps={{ maxLength: 4 }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2} md={2}>
          <Autocomplete
            options={juzgados.sort((a, b) =>
              a.materia.localeCompare(b.materia)
            )}
            getOptionLabel={(option) => option.nombre || ""}
            groupBy={(option) => option.materia}
            disabled={item != null}
            value={formData?.juzgado || null}
            onChange={handleJuzgadoChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Juzgado"
                variant="outlined"
                error={!!errorMessages.juzgado}
                helperText={errorMessages.juzgado}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={2}>
          <Button
            variant="contained"
            className='button-validation'
            disabled={item}
            onClick={handleValidate}
          >
            Validar
          </Button>
        </Grid>

        {/* Divider Vertical */}
        <Grid item xs={1} md={1} sx={{ position: "relative" }}>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              display: { xs: "none", md: "block" },
              position: "absolute",
              top: 0,
              bottom: 0,
              height: "100vh",
            }}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={2}>
          <Button variant="contained" color="primary">
            agenda
          </Button>
        </Grid>
      </Grid>

      <>
        <Grid container spacing={1} sx={{ marginTop: 3 }}>
          <Grid item xs={12} sm={6} md={2}>
            <Typography>Actor: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={10}>
            <Typography style={{ color: "gray" }}>{carpeta.actor}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography>Demandado: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Typography style={{ color: "gray" }}>
              {carpeta.demandado || ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Divider sx={{ mb: 3 }} />
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={2} md={2}>
            <Autocomplete
              options={audiencias}
              getOptionLabel={(option) => option.nombre}
              value={audiencias.find((audiencia) => audiencia.id === formData.tipo) || null}
              onChange={(event, newValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  tipo: newValue ? newValue.id : "",
                }));
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo"
                  variant="outlined"
                  error={!!errorMessages.tipo}
                  helperText={errorMessages["tipo"] || ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={2} md={2}>
            <FormControl fullWidth>
              <InputLabel id="select-sala-label">Sala</InputLabel>
              <Select
                name="sala"
                labelId="select-sala-label"
                value={formData.sala || ""}
                onChange={handleSalaChange}
                label="Sala"
                error={!!errorMessages.sala}
              >
                {salas.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: "error.main" }}>
                {errorMessages["sala"] || ""}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={2} md={2}>
            <FormControl fullWidth>
              <TextField
                name="fecha"
                type="date"
                label="Fecha"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={formData.fecha || ""}
                error={!!errorMessages.fecha}
                onChange={handleChange}
                inputProps={{min: minFecha}}
              />
              <FormHelperText sx={{ color: "error.main" }}>
                {errorMessages["fecha"] || ""}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={2} md={2}>
            <FormControl fullWidth>
              <TextField
                name="hora"
                type="time"
                label="Hora"
                variant="outlined"
                fullWidth
                inputProps={{ step: 1 }}
                InputLabelProps={{ shrink: true }}
                error={!!errorMessages.hora}
                value={formData.hora || ""}
                onChange={handleChange}
              />
              <FormHelperText sx={{ color: "error.main" }}>
                {errorMessages["hora"] || ""}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={2} md={2}>
            <FormControl fullWidth>
              <InputLabel id="select-sala-label">Duración estimada</InputLabel>
              <Select
                name="duracion"
                labelId="select-duracion-label"
                value={formData.duracion || ""}
                onChange={handleChange}
                label="Duracion estimada"
                error={!!errorMessages.duracion}
              >
                {duraciones.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: "error.main" }}>
                {errorMessages["duracion"] || ""}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item xs={8} sm={8} md={8}>
            <TextField
              name="descripcion"
              multiline
              rows={3}
              FormHelperTextProps={{ style: { textAlign: "right" }, }}
              label="Descripción (Notas)"
              variant="outlined"
              fullWidth
              error={!!errorMessages.descripcion}
              helperText={errorMessages.descripcion || ""}
              value={formData.descripcion || ""}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default ReprogramarAudiencia;
