import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useToastAlertValidation,
} from "../../../components/Alert/ToastAlertValidation/ToastAlertValidation.js";
import SweetAlertSave from "../../../components/Alert/SweetAlertSave/SweetAlertSave.js";
import { sendExhorto } from "../ExhortoService.js";
import KeycloakConf from "../../../api/keycloakConf";
import { getSello } from "../../Demanda/DemandaService.js";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import Acciones from "../../../components/Acciones/Acciones.js";
import CamposExhorto from "./RegistroExhorto/CamposExhorto.js";
import AnexosExhorto from "./RegistroExhorto/AnexosExhorto.js";


function FormularioExhortos() {
  const { getToken } = useContext(KeycloakConf);
  const { showSnackbar } = useToastAlertValidation();


  const [formData, setFormData] = useState({
    tipoExhorto: "",
    procedencia: "",
    observaciones: "",
    anexos: [],
  });

  const anexoRefs = useRef([]);
  const [anexosErrors, setAnexosErrors] = useState([]);

  const [errors, setErrors] = useState({
    tipoExhorto: false,
    procedencia: false,
    observaciones: false,
  });

  const [touched, setTouched] = useState({
    tipoExhorto: false,
    procedencia: false,
    observaciones: false,
  });

  const [isEditAnexos, setIsEditAnexos] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [fechaHoraActual, setFechaHoraActual] = useState({
    fecha: "",
    hora: "",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setFechaHoraActual({
        fecha: now.toLocaleDateString(),
        hora: now.toLocaleTimeString(),
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  const tiposExhortos = [
    "Exhorto",
    "Carta rogatoria",
    "Despacho",
    "Cooperación Judicial e Internacional",
    "Oficio",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let hasError = false;
    const newErrors = {
      tipoExhorto: !formData.tipoExhorto,
      procedencia: !formData.procedencia,
    };

    if (newErrors.tipoExhorto || newErrors.procedencia) {
      hasError = true;
    }

    setErrors(newErrors);

    if (hasError) {
      setTouched({
        tipoExhorto: true,
        procedencia: true,
        observaciones: true,
      });

      showSnackbar("Todos los campos deben ser completados.", "error");
      return false;
    }

    return true;
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (!isValid) return;

    const newAnexosErrors = formData.anexos.map((anexo, index) => anexo.length < 3 ? index + 1 : "");
    setAnexosErrors(newAnexosErrors);

    const errors = newAnexosErrors.filter((error) => error !== "");
    if (errors.length === 1) {
      const errorMessage = `El anexo ${errors.join(", ")} debe tener al menos 3 caracteres.`;
      showSnackbar(errorMessage, "error");
      return;
    } else if (errors.length > 0) {
      const errorMessage = `Los anexos ${errors.join(", ")} deben tener al menos 3 caracteres.`;
      showSnackbar(errorMessage, "error");
      return;
    }

    setModalOpen(true);
  };

  const handleConfirm = async () => {
    try {
      const token = getToken();
      const response = await sendExhorto(token, formData);

      showSnackbar("Exhorto generado con éxito", "success");

      const sello = await getSello(token, response.id);
      var blob = new Blob([sello], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "sello.pdf";
      link.click();

      navigate("/api/bandeja/entrada");
    } catch (error) {
      if (error.response && error.response.data[0]) {
        showSnackbar(error.response.data[0].message, "error");
      } else {
        showSnackbar("¡Algo salió mal!", "error");
      }
    } finally {
      setModalOpen(false);
    }
  };

  const handleSubmit = async () => {
    SweetAlertSave.showSuccess();
  };

  const handleAnexoChange = (index, value) => {
    const newAnexos = [...formData.anexos];
    newAnexos[index] = value;
    setFormData({ ...formData, anexos: newAnexos });

    const newAnexosErrors = [...anexosErrors];
    newAnexosErrors[index] = value.length >= 3 ? "" : `El anexo ${index + 1} debe tener al menos 3 caracteres.`;
    setAnexosErrors(newAnexosErrors);
  };

  const handleAddAnexo = () => {
    setFormData({ ...formData, anexos: [...formData.anexos, ""] });
    setIsButtonClicked(true);

    setTimeout(() => {
      const lastAnexoRef = anexoRefs.current[formData.anexos.length];
      if (lastAnexoRef) lastAnexoRef.focus();
    }, 0);
  };

  const handleRemoveAnexo = (index) => {
    const newAnexos = formData.anexos.filter((_, i) => i !== index);
    setFormData({ ...formData, anexos: newAnexos });

    if (newAnexos.length === 0) {
      setIsButtonClicked(false);
    }
  };

  const isEditRoute = location.pathname === "/api/workflow/exhorto";

  const handleClose = async () => {
    navigate("/api/bandeja/entrada");
  };

  const handleTipoExhortoChange = (event) => {
    setFormData({
      ...formData,
      tipoExhorto: event.target.value,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container> 
        <Acciones accion1={handleButtonClick} accion2={handleClose} />

        <Grid className="box-sombra">
          <Box sx={{ p: 3, mx: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CamposExhorto
                  formData={formData}
                  handleTipoExhortoChange={handleTipoExhortoChange}
                  handleChange={handleChange}
                  tiposExhortos={tiposExhortos}
                  errors={errors}
                  touched={touched}
                />



              </Grid>


              <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography sx={{ minWidth: "150px" }}>
                    Anexos
                  </Typography>
                  <Box sx={{ flexGrow: 2 }}>
                    {formData.anexos.map((anexo, index) => (
                      <Grid container key={index} sx={{ mb: 1 }}>
                        <Grid item xs={11}>
                          <TextField
                            label={`Anexo ${index + 1}`}
                            fullWidth
                            value={anexo}
                            onChange={(e) => handleAnexoChange(index, e.target.value)}
                            inputRef={(el) => (anexoRefs.current[index] = el)}
                            helperText={anexosErrors[index] && "El anexo debe tener al menos 3 caracteres"}
                            FormHelperTextProps={{
                              style: {
                                color: anexosErrors[index] !== "" ? "#d32f2f" : "inherit",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => handleRemoveAnexo(index)}
                          >
                            <DeleteIcon className='icon-color'/>
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}

                    {/* Button anexos */}
                    <Grid item xs={12}>
                      <Button
                        className='button-validation'
                        startIcon={<AddCircleOutlineIcon />}
                        variant="contained"
                        onClick={handleAddAnexo}
                      >
                        Agregar Anexo
                      </Button>
                    </Grid>
                  </Box>
                </Box>



              </Grid>


            </Grid>
          </Box>
        </Grid>
      </Grid>

      <ConfirmationModal
        open={modalOpen && !isEditAnexos}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        title="¿Generar exhorto?"
        message="Se generará un nuevo exhorto en el sistema."
        confirmationButton="Sí, generar"
        closeButton="No, cancelar"
      />
    </form>
  );
}

export default FormularioExhortos;
