import { Box, Button, Grid } from "@mui/material";

const AccionesOnlyButtons = ({ accion1, textoBoton1 = "Guardar", accion2, textoAccion2 = "Cerrar" }) => {
    return (
        <Grid item xs={12}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                    pb: 1.5,
                    borderBottom: "1px solid #e0e0e0",
                }}
            >
                {/* Sección de botones */}
                <Box>
                    <Button
                        type="button"
                        variant="contained"
                        className="button-save"
                        onClick={accion1}
                    >
                        {textoBoton1}
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        className="button-close"
                        onClick={accion2}
                        style={{ marginLeft: "10px" }}
                    >
                        {textoAccion2}
                    </Button>
                </Box>
            </Box>
        </Grid>
    );
};

export default AccionesOnlyButtons;
