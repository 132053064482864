import { Box, Button, Card, CardContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import ConfirmationModal from "../../../../../../components/Modal/ConfirmationModal";

const AcuerdoSentenciaTab = ({
    formData,
    handleInputChange,
    setFormData,
    errors,
    validacionCampos,
    isSentencia,
    isDisabled
}) => {
    const [template, setTemplate] = useState("");
    const [templates, setTemplates] = useState([{ id: 1, nombreCompleto: 'dsd' }]);
    const [modalOpenPublicarOficio, setModalOpenPublicarOficio] = useState(false);

    const handleObtenerOficioCargado = () => {

    }

    const handleLoadTemplate = () => {

    }

    const handlePublicarAcuerdo = () => { validacionCampos(true); }


    return <>
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item sm={4}>
                <FormControl fullWidth>
                    <InputLabel id="select-tamanio-papel-label">Tamaño de papel</InputLabel>
                    <Select
                        name="acuerdo.tamanioPapel"
                        labelId="select-tamanio-papel-label"
                        value={formData.acuerdo.tamanioPapel || ""}
                        onChange={handleInputChange}
                        disabled={ isDisabled }
                        label="Tamaño de papel"
                        error={!!errors["acuerdo.tamanioPapel"]}
                    >
                        <MenuItem value="c">Carta</MenuItem>
                        <MenuItem value="o">Oficio</MenuItem>
                    </Select>
                    <FormHelperText >{errors["acuerdo.tamanioPapel"] || ''}</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item sm={4}>
                <InputLabel id="select-acuerdo-label">{ isSentencia ? "Sentencia" : "Acuerdo" }</InputLabel>
                { /*  CONTAINER ACUERDO */}

                {formData.acuerdo.acuerdoPublicado === "S" && (
                    <Card sx={{ maxWidth: 130, margin: "0 auto", position: "relative" }}>

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 80 }}>
                            <PictureAsPdfIcon sx={{ fontSize: 75, color: "black" }} />
                        </Box>

                        <CardContent>
                            <a href="#" onClick={handleObtenerOficioCargado}>acuerdo.pdf</a>
                        </CardContent>
                    </Card>
                )}
            </Grid>

            <Grid item sm={4}>
                <FormControl fullWidth>
                    <InputLabel id="select-tamanio-papel-label">Cargar Template</InputLabel>
                    <Select
                        name="template.acuerdo"
                        labelId="select-template-papel-label"
                        value={template}
                        onChange={handleLoadTemplate}
                        label="Tamaño de papel"
                        disabled={ isDisabled }
                    >
                        {templates.map((template) => (
                            <MenuItem key={template.id} value={template.id}>
                                {template.nombreCompleto}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item sm={12}>
                <Button  disabled={ isDisabled } sx={{ mb: 2 }} variant="contained" className="button-save" onClick={() => setModalOpenPublicarOficio(true)}>
                    <Typography variant="button">Publicar</Typography>
                </Button>

                <Editor
                    apiKey='zb00d05qfmbbu6nyr7rvemq1siihfac4ehlonnhfjajbe589'
                    value={formData.acuerdo.textoEditor}
                    disabled={ isDisabled }
                    onEditorChange={(newValue) =>
                        setFormData((prevState) => ({
                            ...prevState,
                            acuerdo: {
                                ...prevState.acuerdo,
                                textoEditor: newValue
                            }
                        }))
                    }
                    init={{
                        height: 400,
                        menubar: 'edit insert view format table',
                        menu: {
                            edit: { title: 'Edit', items: 'cut copy paste pastetext | selectall | searchreplace' },
                            view: { title: 'View', items: 'visualaid visualchars visualblocks | spellchecker  | showcomments' },
                            insert: { title: 'Insert', items: 'image link  addcomment pageembed codesample inserttable | math | charmap emoticons hr' },
                            format: { title: 'Format', items: 'underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat' },
                            tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount' },
                            table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
                        },
                        language: 'es',
                        branding: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table', 'anchor'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | removeformat',
                        statusbar: false,
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />


            </Grid>
            <Grid item sm={12}>
                <FormControl fullWidth >
                    <FormHelperText >{errors["acuerdo.textoEditor"] || ''}</FormHelperText>
                </FormControl>
            </Grid>

        </Grid>

        <ConfirmationModal
            open={modalOpenPublicarOficio}
            onClose={() => setModalOpenPublicarOficio(false)}
            onConfirm={handlePublicarAcuerdo}
            title={ isSentencia ? "Publicar sentencia" : "Publicar acuerdo" }
            message={ isSentencia ? "Se publicara la sentencia." : "Se publicará el acuerdo."}
            confirmationButton="Sí, publicar"
            closeButton="No, salir"
        />
    </>
}

export default AcuerdoSentenciaTab;