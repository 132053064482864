import React, { useEffect, useState, useContext } from 'react';
import { Box, Button, Divider, Grid, Typography, TextField, Tooltip } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TablaActores from "./TablaActores";
import KeycloakConf from '../../../../../api/keycloakConf';
import { fetchInfoExpediente } from '../../../../../shared/services/CarpetaService';
import { useExpediente } from '../../../../../components/Breadcrumb/ExpedienteContext';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom'; 
import { useItem } from '../../../../../components/ItemProvider/ItemContext';

const demandTypeConfig = {
    laboral: {
        tipo: "Laboral",
        fields: [
            { key: "tipoJuicio", label: "Tipo de juicio", editable: false },
            { key: "juezAsignado", label: "Juez asignado", editable: false },
            { key: "fechaPresentacion", label: "Fecha de presentación", editable: false },
            { key: "tipoProcedimiento", label: "Tipo de procedimiento", editable: false },
            { key: "rubros", label: "Rubros", editable: false },
            { key: "etapaProcesal.etapaProcesal", label: "Etapa procesal", editable: false },
        ],
    },
    penal: {
        tipo: "Penal",
        fields: [
            { key: "tipoCausa", label: "Tipo de causa", editable: false },
            { key: "juezAsignado", label: "Juez asignado", editable: false },
            { key: "fechaPresentacion", label: "Fecha de presentación", editable: false },
            { key: "asunto", label: "Asunto", editable: false },
            { key: "etapaProcesal.etapaProcesal", label: "Etapa procesal", editable: false },
        ],
    },
    exhorto: {
        tipo: "Exhorto",
        fields: [
            { key: "fechaPresentacion", label: "Fecha de Presentación", editable: false },
        ],
    },
    other: {
        tipo: "Mercantil, Civil, Familiar",
        fields: [
            { key: "tipoJuicio", label: "Tipo de acciones", editable: false },
            { key: "juezAsignado", label: "Juez asignado", editable: false },
            { key: "fechaPresentacion", label: "Fecha de presentación", editable: false },
            { key: "etapaProcesal.etapaProcesal", label: "Etapa procesal", editable: false },
        ],
    }
};

const General = ({ isConsulta}) => {
    const { getToken } = useContext(KeycloakConf);
    const navigate = useNavigate();
    const { setExpedienteSeleccionado } = useExpediente();
    const { item, setItem } = useItem();
    console.log(item);
    
    const [general, setGeneral] = useState({
        expediente: "",
        tipoJuicio: "",
        tipoCausa: "",
        juezAsignado: "",
        fechaPresentacion: "",
        asunto: "",
        tipoProcedimiento: "",
        rubros: "",
        etapaProcesal: "",
        participantes: [],
        razonDevolucion: ""
    });
    const [tipoDemanda, setTipoDemanda] = useState("");

    const getTipoDemanda = (materia, tipoSistema, numExpediente) => {
        if (!materia) return "";
        if (!tipoSistema) tipoSistema = "";
        const materiaLower = materia.toLowerCase();
        const tipoSistemaLower = tipoSistema.toLowerCase();

        let demandType = materiaLower;
        let expedienteSeleccionado = "Expediente ";

        if (materiaLower) {
            if (materiaLower === "mercantil" ||
                materiaLower === "civil" ||
                materiaLower === "familiar" ){
                    demandType = "other";
            }

            if(tipoSistemaLower === "oral"){
                expedienteSeleccionado = "Carpeta Digital ";
            }

            if(materiaLower === "penal"){
                expedienteSeleccionado = "Causa ";
            }

            if(materiaLower === "exhorto"){
                expedienteSeleccionado = "Exhorto ";
            }
        }
        
        //asigna expediente para breadcrums
        expedienteSeleccionado = expedienteSeleccionado + numExpediente;
        setExpedienteSeleccionado(expedienteSeleccionado);
        localStorage.setItem('expedienteSeleccionado', JSON.stringify(expedienteSeleccionado));

        return demandType;
    };
    
    const loadInfoExpediente = async () => {
        const token = getToken();
        try {
            const response = await fetchInfoExpediente(token, item.id);
          
            setItem(prevState => ({
                ...prevState,
                juzgadoNombre: response.juzgadoNombre,
                etapaProcesal: response.etapaProcesal
            }));
            
            console.log("Item construido: ", item);
            
            setGeneral(response);
            const tipoDemanda = getTipoDemanda(response.materia, response.tipoSistema, response.expediente);
            setTipoDemanda(tipoDemanda);
        } catch (error) {
            console.error('Error cargando información de expediente:', error);
        }
    };
    
    useEffect(() => {
        loadInfoExpediente();
    }, []);
 
    const handleNewParticipante = () => {
        navigate("/api/agregar-participante", { state: { item } });
    };

    const handleDetalle = () => {
        navigate('/api/bandeja/expediente/detalle', { state: { item, general, isConsulta: isConsulta } });
    }
    const handleDeleteParticipante = (id) => {
        setGeneral((prev) => ({
            ...prev,
            participantes: prev.participantes.map((participante) => ({
                ...participante,
                data: participante.data.filter((actor) => actor.id !== id),
            })),
        }));
    };

    const demandaConfig = demandTypeConfig[tipoDemanda] || { fields: [] };
    const fields = [...demandaConfig.fields];

    if (item.tipoEntrada === "Pieza") {
        fields.splice(fields.findIndex(field => field.key === "etapaProcesal"), 0, {
            key: "tipoPieza",
            label: "Tipo de pieza",
            editable: false
        });
    }

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((acc, key) => acc && acc[key], obj);
    };

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
                {item.tipoEntrada !== "Pieza" && (
                    <Button variant="contained" className="button-save" onClick={handleDetalle}>
                        Detalle
                    </Button>
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                {item.tipoEntrada === "Pieza" && (
                    <Typography style={{ color: "Red", textAlign: "right" }}>
                        Razones de la devolución: {general.razonDevolucion || "General"}
                    </Typography>
                )}
            </Grid>
        </Grid>

        <form>
            <Grid container spacing={2} sx={{ mt: 2, mb: 5 }}>
                {fields.map(field => (
                    <Grid item xs={12} sm={12} md={4} sx={{ mb: 2 }} key={field.key}>
                        {field.editable ? (
                            <TextField
                                label={field.label}
                                name={`general.${field.key}`}
                                value={getNestedValue(general, field.key) || ""}
                                onChange={(e) => setGeneral(prev => ({ ...prev, [field.key]: e.target.value }))}
                                fullWidth
                            />
                        ) : (
                            <>
                                <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                    {item.tipoEntrada === "Pieza" && field.key === "tipoJuicio"
                                    ? "Tipo de causa"
                                    : field.label}:
                                </Typography>
                                {field.key === "rubros" ? (
                                    // Lógica específica para rubros
                                    <Typography>
                                        {Array.isArray(getNestedValue(general, field.key))
                                            ? getNestedValue(general, field.key)
                                                .map(rubro => rubro.name)
                                                .join(", ")
                                            : getNestedValue(general, field.key)?.name || ""}
                                    </Typography>
                                ) : (
                                    <Typography>
                                        {item.tipoEntrada === "Pieza" && field.key === "tipoJuicio"
                                        ? general.asunto
                                        : getNestedValue(general, field.key) || ""}
                                    </Typography>
                                )}
                            </>
                        )}
                    </Grid>
                ))}
            </Grid>
        </form>

        <Box sx={{ marginTop: 2 }}>
            <Divider />
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
        <Tooltip title="Agregar participante">
            <IconButton  className="icon-color" onClick={handleNewParticipante} disabled={item.tipoEntrada === "Pieza"  || !general.etapaProcesal || isConsulta}>
                <PersonAddIcon />
            </IconButton>
        </Tooltip>
        </Box>
        <Typography variant="h7">Participantes</Typography>

        {general.participantes.map((participante, index) => (

            <Accordion key={`accordion-header-${participante.tipo}-${index}`}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >

                    <Typography variant="body1">{participante.tipo}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* Campos mostrados para expediente */}
                    {participante.tipo === "Actor" && (
                        <TablaActores key={`${participante.tipo}-${index}`} data={participante.data} tipo={"Actor"} onDelete={handleDeleteParticipante} isPieza={item.tipoEntrada === "Pieza"} hasNotEtapaProcesal={!general.etapaProcesal} isDisabled={isConsulta} item={item} />
                    )}
                    {participante.tipo === "Demandado" && (
                        <TablaActores key={`${participante.tipo}-${index}`} data={participante.data} tipo={"Demandado"} onDelete={handleDeleteParticipante} isPieza={item.tipoEntrada === "Pieza"} hasNotEtapaProcesal={!general.etapaProcesal} isDisabled={isConsulta} item={item}/>
                    )}
                    {participante.tipo === "Ministerio Público" && (
                        <TablaActores key={`${participante.tipo}-${index}`} data={participante.data} tipo={"Ministerio Público"} onDelete={handleDeleteParticipante} isPieza={item.tipoEntrada === "Pieza"} hasNotEtapaProcesal={!general.etapaProcesal} isDisabled={isConsulta} item={item} />
                    )}
                    {participante.tipo === "Tercero Involucrado" && (
                        <TablaActores key={`${participante.tipo}-${index}`} data={participante.data} tipo={"Tercero Involucrado"} onDelete={handleDeleteParticipante} isPieza={item.tipoEntrada === "Pieza"} hasNotEtapaProcesal={!general.etapaProcesal} isDisabled={isConsulta} item={item}/>
                    )}
                    {participante.tipo === "Tercero Interesado - Testigo" && (
                        <TablaActores key={`${participante.tipo}-${index}`} data={participante.data} tipo={"Tercero Interesado - Testigo"} onDelete={handleDeleteParticipante} isPieza={item.tipoEntrada === "Pieza"} hasNotEtapaProcesal={!general.etapaProcesal} isDisabled={isConsulta} item={item}/>
                    )}
                    {participante.tipo === "Actor - Promovente" && (
                        <TablaActores key={`${participante.tipo}-${index}`} data={participante.data} tipo={"Actor - Promovente"} onDelete={handleDeleteParticipante} isPieza={item.tipoEntrada === "Pieza"} hasNotEtapaProcesal={!general.etapaProcesal} isDisabled={isConsulta} item={item}/>
                    )}
                    {participante.tipo === "Actor - Abogado autorizado, pero no patrono" && (
                        <TablaActores key={`${participante.tipo}-${index}`} data={participante.data} tipo={"Actor - Abogado autorizado, pero no patrono"} onDelete={handleDeleteParticipante} isPieza={item.tipoEntrada === "Pieza"} hasNotEtapaProcesal={!general.etapaProcesal} isDisabled={isConsulta} item={item}/>
                    )}
                    {/* Campos mostrados para penal */}
                    {participante.tipo === "Víctimas" && (
                        <TablaActores key={`${participante.tipo}-${index}`} data={participante.data} tipo={"Víctimas"} onDelete={handleDeleteParticipante} isPieza={item.tipoEntrada === "Pieza"} hasNotEtapaProcesal={!general.etapaProcesal} isDisabled={isConsulta} item={item}/>
                    )}
                    {participante.tipo === "Imputados" && (
                        <TablaActores key={`${participante.tipo}-${index}`} data={participante.data} tipo={"Imputados"} onDelete={handleDeleteParticipante} isPieza={item.tipoEntrada === "Pieza"} hasNotEtapaProcesal={!general.etapaProcesal} isDisabled={isConsulta} item={item}/>
                    )}
                    {participante.tipo === "Parte Ofendida" && (
                        <TablaActores key={`${participante.tipo}-${index}`} data={participante.data} tipo={"Parte Ofendida"} onDelete={handleDeleteParticipante} isPieza={item.tipoEntrada === "Pieza"} hasNotEtapaProcesal={!general.etapaProcesal} isDisabled={isConsulta} item={item}/>
                    )}
                </AccordionDetails>
            </Accordion>
        ))}
    </>
}


export default General;