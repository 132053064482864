import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import { fetchEtapasProcesales } from "../../../../../../shared/services/EtapaProcesalService";
import KeycloakConf from '../../../../../../api/keycloakConf';


const DetalleMercantilCivil = ({ 
  formData, 
  setFormData, 
  tiposJuicio,
  general,
  isConsulta
}) => {

const [etapaProcesal, setEtapaProcesal] = useState([]);
const { getToken } = useContext(KeycloakConf);
const token = getToken();
const [etapaFlag, setEtapaFlag] = useState(false);

useEffect(() => {
  if (!formData.determinacion) {
    setFormData((prevData) => ({
      ...prevData,
      determinacion: "PRESENTACION",
    }));
  }
}, [token, formData.tiposJuicio]);

useEffect(() => {
  if (!isValueInListTipoJuicio(tiposJuicio, formData.tipoJuicioHijoId)) {
    setFormData((prevData) => ({
      ...prevData,
      tipoJuicioHijoId: ""
    }));
  }
}, [tiposJuicio]);

useEffect(() => {
  if(formData.tipoJuicioId){
    loadEtapasProcesales();
  }
}, [token, formData.tipoJuicioId]);

const loadEtapasProcesales = async () => {
  try {
    const response = await fetchEtapasProcesales(token, formData.tipoJuicioId);
    setEtapaProcesal(response);
    setEtapaFlag(true);
  } catch (error) {
    console.error("Error al obtener las etapas procesales");
  }
};

const isValueInListTipoJuicio = (list, value) => {
  return list.some((item) => item.id === value);
};

useEffect(() => {
  if (!isValueInList(etapaProcesal, formData.etapaProcesal) && etapaFlag) {
    setFormData((prevData) => ({
      ...prevData,
      etapaProcesal: ""
    }));
  }
  if(etapaProcesal && formData.etapaProcesal && formData.etapaProcesal.id){
    const selectedEtapa = etapaProcesal.find(
      (etapa) => etapa.id === formData.etapaProcesal.id
    );
    if (selectedEtapa) {
      setFormData((prevData) => ({
        ...prevData,
        etapaProcesal: selectedEtapa,
      }));
    }
  }
}, [etapaProcesal]);

const isValueInList = (list, value) => {
  return list.some((item) => JSON.stringify(item) === JSON.stringify(value));
};
const generateMenuItems = (items, key, labelKey = "name") => {
  return items.map((item) => (
    <MenuItem key={item[key]} value={item}>
      {item[labelKey]}
    </MenuItem>
  ));
};

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

  return (
  <Grid container spacing={2} sx={{ marginTop: 1 }}>
    <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Juez"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {"Juez"}:
          </Typography>
          <Typography>{general.juezAsignado || ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Materia"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {"Materia"}:
          </Typography>
          <Typography>{general.materia || ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Fecha de presentación"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {"Fecha de presentación"}:
          </Typography>
          <Typography>{general.fechaPresentacion || ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Ubicación"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {"Ubicación"}:
          </Typography>
          <Typography>{formData.ubicacion || ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel id="determinacion-label">Determinación</InputLabel>
            <Select
              labelId="determinacion-label"
              name="determinacion"
              value={formData.determinacion || "PRESENTACION"}
              onChange={handleChange}
              label="Determinación"
              disabled={isConsulta}
            >
                <MenuItem key={"PRESENTACION"} value={"PRESENTACION"}>{"Presentación"}</MenuItem>
                <MenuItem key={"ADMITIDA"} value={"ADMITIDA"}>{"Admitida"}</MenuItem>
                <MenuItem key={"ADMITIDA_CON_PREVENCION"} value={"ADMITIDA_CON_PREVENCION"}>{"Admitida con prevención"}</MenuItem>
                <MenuItem key={"DESECHADA"} value={"DESECHADA"}>{"Desechada"}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"fechaAdmision"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {"Fecha admisión"}:
          </Typography>
          <Typography>{formData.fechaAdmision || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="tipoJuicioHijoId-label">Tipo de acciones</InputLabel>
          <Select
            labelId="tipoJuicioHijoId-label"
            name="tipoJuicioHijoId"
            value={formData.tipoJuicioHijoId || ""}
            onChange={(e) => {
              const selectedId = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                tipoJuicioHijoId: selectedId,
              }));
            }}
            label="Tipo de juicio"
            disabled={isConsulta}
          >
            <MenuItem value="">Seleccionar</MenuItem>
            {tiposJuicio.map((tipo) => (
              <MenuItem key={tipo.id} value={tipo.id}>
                {tipo.nombre}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </Grid>
        {formData.sentencia?
          <Grid item xs={12} sm={6} md={4}>
            <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                {"Sentencia"}:
            </Typography>
            <Typography>{general.sentencia}</Typography>
          </Grid>
          :<></>
        }
        {formData.fechaDesechado? 
          <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Fecha desechada"}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }}>
              {"Fecha de desechada"}:
            </Typography>
            <Typography>{formData.fechaDesechado || ""}</Typography>
          </Grid>
          :
          <></>
        }
      <Grid item xs={12} sm={6} md={4}>
        <FormControl
          fullWidth
          variant="outlined"
        >
          <InputLabel id="tipo-label">Etapa procesal</InputLabel>
          <Select
            labelId="etapa-label"
            name="etapaProcesal"
            value={formData.etapaProcesal || ""}
            onChange={handleChange}
            label="Etapa Procesal"
            disabled={isConsulta}
          >
            <MenuItem value="">Seleccionar</MenuItem>
            {generateMenuItems(etapaProcesal, "id", "etapaProcesal")}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          name="cantidadPrincipal"
          label="Cantidad Principal"
          variant="outlined"
          fullWidth
          value={formData.cantidadPrincipal || ""}
          onChange={handleChange}
          disabled={isConsulta}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          name="moneda"
          label="Moneda"
          variant="outlined"
          fullWidth
          value={formData.moneda || ""}
          onChange={handleChange}
          disabled={isConsulta}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={8}>
        <TextField
          name="observaciones"
          label="Observaciones"
          variant="outlined"
          fullWidth
          value={formData.observaciones || ""}
          onChange={handleChange}
          disabled={isConsulta}
        />
      </Grid>
    </Grid>
  );
};

export default DetalleMercantilCivil;
