import React, { useEffect, useState, useContext } from 'react';
import { Box } from "@mui/material";
import NotificacionTable from "./Grid/NotificacionTable";
import KeycloakConf from '../../api/keycloakConf';
import ConfirmationDialog from "../../components/Alert/ConfirmationDialog";
import ToastAlertDelete from "../../components/Alert/ToastAlertDelete/ToastAlertDelete";
import RefreshButton from "../../components/Button/RefreshButton";
import SearchInput from "../../components/Search/SearchInput";
import { findAll } from './NotificacionService';
import NotificacionFiltros from './Grid/NotificacionFiltros';

const Notificacion = () => {
    const [notificacionData, setNotificacionData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const { getToken } = useContext(KeycloakConf);
    const [pageable, setPageable] = useState({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('success');
    const [isDigitalizacion, setIsDigitalizacion] = useState(false);
    const [tipo, setTipo] = useState('');
    const [estado, setEstadoNotificacion] = useState('');

    const [selectedIds, setSelectedIds] = useState([]);

    const handleSelectionChange = (selected) => {
        setSelectedIds(selected);
    };

    useEffect(() => {
        loadNotificacionData();
    }, [page, rowsPerPage, searchQuery, tipo, estado]);

    const loadNotificacionData = async () => {
        setLoading(true);
        const token = getToken();
        try {
            const data = await findAll(token, page, rowsPerPage, '', tipo, estado);
            setNotificacionData(data.content || []);
            setPageable(data.page || {});
        } catch (error) {
            console.error('Error al cargar datos de notificaciones:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleRefresh = () => {
        setSearchQuery('');
        setPage(0);
        loadNotificacionData();
    };

    return (
        <>
            {!isDigitalizacion && (
                <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <RefreshButton onClick={handleRefresh} />
                    </Box>
                    <Box flexBasis="300px" ml={2}>
                        <SearchInput
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Buscar..."
                            ariaLabel="Campo de búsqueda para notificaciones"
                            tooltipTitle="Escriba para buscar"
                        />
                    </Box>
                </Box>
            )}

            <Box mb={4} mt={4}>
                <NotificacionFiltros
                    setTipo={setTipo}
                    setEstadoNotificacion={setEstadoNotificacion}
                    isButtonEnabled={selectedIds.length > 0}
                    cantidadNotificaciones={selectedIds.length}
                    selectedIds={selectedIds}
                    loadNotificacionData={loadNotificacionData}
                />
            </Box>

            {loading ? <p>Cargando...</p> : (
                <NotificacionTable
                    data={notificacionData}
                    token={getToken()}
                    setIsDigitalizacion={setIsDigitalizacion}
                    loadNotificacionData={loadNotificacionData}
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    onSelectionChange={handleSelectionChange}
                    fetchData={handleRefresh}
                />
            )}

            <ConfirmationDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                message={dialogMessage}
            />

            <ToastAlertDelete
                open={toastOpen}
                onClose={() => setToastOpen(false)}
                message={toastMessage}
                type={toastType}
            />
        </>
    );
};

export default Notificacion;
