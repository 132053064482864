import { useState } from "react";
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Checkbox,
} from "@mui/material";
import PaginationGeneric from "../../../components/Pagination/PaginationGeneric";
import { useNavigate } from "react-router-dom";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RefreshButton from "../../../components/Button/RefreshButton";
import AddCartButton from "../../../components/Button/AddCartButton";
import text from "../../../assets/Glosario";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BandejaSalidaTable = ({
  bandejas,
  pageable,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  loadBandejaSalida,
  setToastOpen,
  loading,
  cartItems,
  setCartItems,
  setToastMessage,
  setToastTitle,
}) => {
  const { showSnackbar } = useToastAlert();
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelectRow = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSingleAddCarrito = (movid) => {
    const itemToAdd = bandejas.find((item) => item.movid === movid);
    const isItemInCart = cartItems.some((cartItem) => cartItem.movid === movid);
    if (!isItemInCart && itemToAdd) {
      setCartItems([...cartItems, itemToAdd]);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = bandejas.map((item) => item.movid);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const addToCarrito = () => {
    const selectedItems = bandejas.filter((item) =>
      selected.includes(item.movid)
    );

    const newItemsToAdd = selectedItems.filter(
      (item) => !cartItems.some((cartItem) => cartItem.movid === item.movid)
    );

    setCartItems([...cartItems, ...newItemsToAdd]);
    setSelected([]);

    if (newItemsToAdd.length > 0) {
      setToastTitle("Registros agregados");
      setToastMessage(
        "Se agregaron " + newItemsToAdd.length + " registros al carrito."
      );
    } else {
      setToastTitle("Seleccione registros");
      setToastMessage("Ningun registro seleccionado.");
    }
    setToastOpen(true);
  };

  const remainingItems = bandejas.filter((item) => !cartItems.includes(item));

  return (
    <>
      <Box mb={2} display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <RefreshButton onClick={loadBandejaSalida} />
        </Box>

        <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
          <AddCartButton onClick={addToCarrito} />
        </Box>
      </Box>

      <Box>
        {loading ? (
          <p>Cargando ... </p>
        ) : (
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
            <PaginationGeneric
                pageable={pageable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
              <TableContainer sx={{ maxHeight: 600, overflowY: "auto" }}>
                <Table stickyHeader sx={{ minWidth: 750 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-header" padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < remainingItems.length
                          }
                          checked={
                            remainingItems.length > 0 &&
                            selected.length === remainingItems.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.Folio}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                       {text.NoExpediente}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.TipoEntrada}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.Mate}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.Destino}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.FechaHora}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.Acciones}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {remainingItems.map((item) => {
                      const isItemSelected = isSelected(item.movid);
                      return (
                        <StyledTableRow
                          key={item.movid}
                          hover
                          style={{ cursor: "pointer" }}
                          selected={isItemSelected}
                          onClick={() => handleSelectRow(item.movid)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={() => handleSelectRow(item.movid)}
                            />
                          </TableCell>
                          <TableCell align="center">{item.folio}</TableCell>
                          <TableCell align="center">
                            {item.expediente}
                          </TableCell>
                          <TableCell align="center">
                            {item.tipoEntrada}
                          </TableCell>
                          <TableCell align="center">{item.materia}</TableCell>
                          <TableCell align="center">{item.juzgado}</TableCell>
                          <TableCell align="center">
                            {new Date(item.fechaRegistro).toLocaleString()}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Agregar al carrito">
                              <AddShoppingCartIcon
                                className="icon-color"
                                onClick={() =>
                                  handleSingleAddCarrito(item.movid)
                                }
                              />
                            </Tooltip>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                    {remainingItems.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No hay registros disponibles.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationGeneric
                pageable={pageable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Paper>
          </Box>
        )}
      </Box>
    </>
  );
};

export default BandejaSalidaTable;
