import { Box, Grid, Button, Typography, Divider, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import keycloakConf from "../../api/keycloakConf";
import { fetchAudienciasGenerales } from "../../shared/AudienciasService";
import BandejaAudienciaTable from "./BandejaAudienciaTable";
import RefreshButton from "../../components/Button/RefreshButton";
import ButtonAdd from '../../components/Button/ButtonAdd';
import InfoIcon from '@mui/icons-material/Info';
import ConfirmationDialog from "../../components/Alert/ConfirmationDialog";
import { removeAudiencia } from "../../shared/AudienciasService";
import Acciones from "../../components/Acciones/Acciones";
const BandejaAudienciasGenerales = () => {
    const navigate = useNavigate();
    const [bandejas, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { getToken } = useContext(keycloakConf);
    const [loading, setLoading] = useState(true);
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [itemToDelete, setItemToDelete] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('success');
    const [toastOpen, setToastOpen] = useState(false);
    const [isTab] = useState(false);

    useEffect(() => {
        loadBandejaAudiencia();

        const interval = setInterval(() => {
            const now = new Date();
            setCurrentDateTime(now.toLocaleString());
        }, 1000);

        return () => clearInterval(interval);
    }, [getToken, page, rowsPerPage]);

    const loadBandejaAudiencia = async () => {
        setLoading(true);
        const token = getToken();
        try {
            const bandejaAudienciaData = await fetchAudienciasGenerales(token, page, rowsPerPage, '', searchQuery);
            setData(bandejaAudienciaData.content);
            setPageable(bandejaAudienciaData.page);
        } catch (error) {
            console.error('Error cargando registros:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleRefresh = () => {
        setSearchQuery('');
        setPage(0);
        loadBandejaAudiencia();
    };
    const handleDelete = (id) => {
        setDialogMessage(`La audiencia se eliminará de forma permanente`);
        setItemToDelete(id);
        setDialogOpen(true);
    };



    const handleConfirmDelete = async () => {
        const token = getToken();
        try {
            await removeAudiencia(token, itemToDelete);

            setToastMessage('Registro eliminado con éxito');
            setToastType('success');
            await loadBandejaAudiencia();
        } catch (error) {
            console.error('Error eliminando registro:', error);
            setToastMessage('El registro no fue eliminado');
            setToastType('error');
        }
        setToastOpen(true);
        setDialogOpen(false);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleCancel = () => {
        navigate("/");
    };

    const [date, time] = currentDateTime.split(', ');
    const toopTips = () => {

        return <>
            <Tooltip title="Audiencia: Acto procesal en el que las partes exponen sus argumentos ante el juez o tribunal para que este tome una decisión sobre el caso.">
                <InfoIcon color="action" />
            </Tooltip>
        </>

    }

    const handleCrearAudiencia = () => {
        navigate("/api/bandeja/crearAudiencias");
    }

    return (
        <>
            <Acciones isVisibleBtn1={false} toolTip={ toopTips } />

            <Box mb={2} display="flex" alignItems="center">
                <Box display="flex" alignItems="center" sx={{ mr: 1 }}>
                    <ButtonAdd onClick={ handleCrearAudiencia } />
                </Box>
                <Box display="flex" alignItems="center">
                    <RefreshButton onClick={handleRefresh} />
                </Box>
            </Box>
            <Box>
                {loading ? <p>Cargando ... </p> : (

                    <BandejaAudienciaTable
                        bandejas={bandejas}
                        token={getToken()}
                        onDelete={handleDelete}
                        loadBandejaAudiencia={loadBandejaAudiencia}
                        pageable={pageable}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        handleRefresh={handleRefresh}
                        isTab={false}
                    />

                )}
            </Box>

            <ConfirmationDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDelete}
                message={dialogMessage}
            />
        </>
    )
};

export default BandejaAudienciasGenerales;