import React, { useState } from 'react';
import {
    Box,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Checkbox
} from '@mui/material';
import PaginationGeneric from '../../../components/Pagination/PaginationGeneric';
import text from '../../../assets/Glosario';
import NoteIcon from '@mui/icons-material/Note';
import AgregarNota from '../ModalesGrid/AgregarNota';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('es-ES', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        timeZone: 'UTC'
    }).format(date);
};


const NotificacionTable = ({
    data,
    pageable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    onSelectionChange,
    fetchData
}) => {

    const [selected, setSelected] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedExpediente, setSelectedExpediente] = useState(null);
    const [selectedConcepto, setSelectedConcepto] = useState('');
    const [selectedId, setSelectedId] = useState(null);

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const allIds = data.map((item) => item.id);
            setSelected(allIds);
            onSelectionChange(allIds);
        } else {
            setSelected([]);
            onSelectionChange([]); // evniar los ids seleccionados
        }
    };

    const handleSelect = (id) => {
        const updatedSelected = selected.includes(id)
            ? selected.filter((selectedId) => selectedId !== id)
            : [...selected, id];
        setSelected(updatedSelected);
        onSelectionChange(updatedSelected); // Notificar al padre
    };


    const sortedData = [...data].sort((a, b) => new Date(b.fechaPublicacion) - new Date(a.fechaPublicacion));

    const handleOpenModal = (id, expediente, concepto) => {
        setSelectedId(id);
        setSelectedExpediente(expediente);
        setSelectedConcepto(concepto);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedExpediente(null);
        setSelectedConcepto('');
    };

    const handleSelectionChange = (selected) => {
        setSelected(selected);
        onSelectionChange(selected.length); // Pasa la cantidad seleccionada 
    };

    const refreshData = async () => {
        if (fetchData) {
            await fetchData(); // Llama a la función para recargar los datos
        }
    };

    return (
        <Box sx={{ width: '100%' }}>

            <Paper sx={{ width: '100%', mb: 2 }}>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header' padding="checkbox">
                                    <Checkbox
                                        indeterminate={selected.length > 0 && selected.length < data.length}
                                        checked={data.length > 0 && selected.length === data.length}
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell className='table-header'>{text.NoExpediente}</TableCell>
                                <TableCell className='table-header'>{text.Concepto}</TableCell>
                                <TableCell className='table-header'>{text.Notas}</TableCell>
                                <TableCell className='table-header'>{text.Tipo}</TableCell>
                                <TableCell className='table-header'>{text.FechaPublicacion}</TableCell>
                                <TableCell className='table-header'>{text.FechaResolucion}</TableCell>
                                <TableCell className='table-header'>{text.Acciones}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((item, index) => (
                                <StyledTableRow
                                    key={item.id}
                                    hover
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selected.includes(item.id)}
                                            onChange={() => handleSelect(item.id)}
                                        />
                                    </TableCell>
                                    <TableCell>{item.expediente}</TableCell>
                                    <TableCell align="center">{item.concepto && item.concepto.length > 0 ? item.concepto : '-'}</TableCell>
                                    <TableCell>{item.notas}</TableCell>

                                    <TableCell>
                                        {item.tipo
                                            .charAt(0)
                                            .toUpperCase() + item.tipo.slice(1).toLowerCase()
                                                .replace(/_/g, ' ')}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.documentoDetalleRecord?.fechaPublicacion
                                            ? formatDate(item.documentoDetalleRecord.fechaPublicacion)
                                            : '-'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.documentoDetalleRecord?.fechaResolucion
                                            ? formatDate(item.documentoDetalleRecord.fechaResolucion)
                                            : '-'}
                                    </TableCell>

                                    <TableCell align="left">
                                        <Tooltip title="Agregar nota">
                                            <NoteIcon          
                                                className="icon-color"
                                                onClick={() => handleOpenModal(item.id, item.expediente, item.concepto)}
                                                style={{ cursor: 'pointer', marginRight: '10px'}}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                            {sortedData.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        No hay registros disponibles.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>
            {isModalOpen && (
                <AgregarNota
                    isOpen={isModalOpen}
                    handleClose={handleCloseModal}
                    expediente={selectedExpediente}
                    concepto={selectedConcepto}
                    id={selectedId}
                    handleSave={refreshData}
                />
            )}
        </Box>
    );
};

export default NotificacionTable;
