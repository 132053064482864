import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox
} from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { useToastAlert } from "../../../../components/Alert/ToastAlertSave/ToastAlertSave";
import RefreshButton from "../../../../components/Button/RefreshButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import PaginationGeneric from "../../../../components/Pagination/PaginationGeneric";
import { fetchDocumentosAndPiezas } from "../../../../shared/services/CarpetaService";
import { getDocumento } from "../../BandejaService";
import { obtenerOficioCargado } from "../../../Oficios/RegistroOficiosService";
import keycloakConf from "../../../../api/keycloakConf";
import ModalPieza from "../../../../components/ModalPieza/ModalPieza";
import { useItem } from "../../../../components/ItemProvider/ItemContext";

const Documentos = ({  isConsulta }) => {
    const [tipoDocumentoSelected, setTipoDocumentoSelected] = useState();
    const [open, setOpen] = useState(false);
    const { getToken } = useContext(keycloakConf);
    const { showSnackbar } = useToastAlert();
    const { item } = useItem();
    console.log("el item de documentos es: ",  item);
    
    //variables de documentos
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [documentosData, setDocumentos] = useState([]);
    const [key, setKey] =useState('');
    const navigate = useNavigate();
    const [tipoDocumentoData, setTipoDocumentos] = useState([
        { id: "ALL", nombre: "General" },
        { id: "PROMO", nombre: "Promoción" },
        { id: "AMPARO", nombre: "Amparo" },
        { id: "PIEZA", nombre: "Pieza" },
        { id: "OFICIO", nombre: "Oficio" },
        { id: "DEMANDA", nombre: "Presentación de Demanda" }
    ]);
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(true);

    const [openModalPieza, setOpenModalPieza] = useState(false);
    const [modalDataPieza, setModalDataPieza] = useState([]);

    useEffect(() => {
        loadDocumentos();
    }, []);

    const loadDocumentos = async (key=null) => {
        setLoading(true);
        //aqui se tiene que remplazaer estos valores estaticos por los que vengan del service que se tiene que crear.
        const token = getToken();
        console.log(item);

        try {
            const documentosData = await fetchDocumentosAndPiezas(token, item, key);
            setDocumentos(documentosData.content);
            setPageable(documentosData.page)
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleTipoDocumentoChange = (e) => { 
        const tipoDoc = e.target.value;

        if (tipoDoc==="ALL"){
            loadDocumentos();
            return;
        }

        loadDocumentos(tipoDoc);

    };

    const handlePieza = (documentoId) => {

        const data = {
            carpetaId: item.carpetaId,
            expediente: item.expediente,
            documentos: documentoId ? [{ id: documentoId, expediente: "" }] : selected.map((i) => ({ id: i, expediente: "" }))
        };

        handleOpenModal(data, "pieza");
    }

    const handleAmparo = () => {
        navigate('/api/workflow/amparo', {
            state: { item, url: '/api/bandeja/expediente' },

        });
    }

    const handleOficio = () => {
        navigate('/api/workflow/oficio', {
            state: { item }
        });
    }

    const handleExhorto = () => {
        navigate("/api/workflow/exhorto/salida", { state: { item } });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    // Función para cerrar el modal
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenModal = (items, modal = "") => {
        setModalDataPieza(items);
        setOpenModalPieza(true);

    };

    const handleCloseModal = (event, modal = "") => {

        if (modal && modal === "pieza") {
            setOpenModalPieza(false);
            setModalDataPieza({});
        }

        setOpenModalPieza(false);

    };

    const handlePDF = async (data) => {
        const token = getToken();
        let documento;

        try {

            if (data.tipo === "OFICIO"){
                documento = await obtenerOficioCargado(token, data.id, null);
            }else if (data.tipo === "ACUERDO"){
                handleOpen();
                return;
            }else{
                documento = await getDocumento(token, data.id);
            }

            let blob = new Blob([documento], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.target = data.ruta;
            link.click();
        } catch (error) {
            console.error("Error al generar el documento:", error);
            showSnackbar("¡Algo salió mal!", "error");
        }
    };

    const handleSelectRow = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleRefresh = () => {
        setPage(0);
        loadDocumentos();
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        '&.disabled td': {
            cursor: 'not-allowed',
            'pointer-events': 'all',
            color: '#BABABA'
        }
    }));

    const StyledButtonSave = styled(Button)(({ theme }) => ({
        '&:disabled': {
            cursor: 'not-allowed',
            'pointer-events': 'all'
        }
    }))

    return (<>
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={2}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="select-juzgado-label">Seleccione una opción</InputLabel>
                    <Select
                        labelId="select-juzgado-label"
                        value={tipoDocumentoSelected?.id || ''}
                        onChange={(e)=>{handleTipoDocumentoChange(e)}}
                        label="Tipo de documento"
                        required
                    >
                        {tipoDocumentoData.map((tipoDoc) => (
                            <MenuItem key={tipoDoc.id} value={tipoDoc.id}>
                                {tipoDoc.nombre}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={2}></Grid>

            <Grid item xs={2}>
                <StyledButtonSave variant="contained" className="button-save" onClick={handlePieza} disabled={selected.length === 0 || item.tipoEntrada === "Pieza" || !item.etapaProcesal}>
                    Pieza
                </StyledButtonSave>
            </Grid>

            <Grid item xs={2}>
                <StyledButtonSave variant="contained" className="button-save" onClick={handleAmparo} disabled={!item.etapaProcesal || isConsulta}>
                    Amparo
                </StyledButtonSave>
            </Grid>

            <Grid item xs={2}>
                <StyledButtonSave variant="contained" className="button-save" onClick={handleOficio} disabled={!item.etapaProcesal || isConsulta}>
                    Oficio
                </StyledButtonSave>
            </Grid>

            <Grid item xs={2}>
                <StyledButtonSave variant="contained" className="button-save" onClick={handleExhorto} disabled={!item.etapaProcesal || isConsulta}>
                    Exhorto
                </StyledButtonSave>
            </Grid>
        </Grid>

        <Box mb={2} mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
                <RefreshButton onClick={handleRefresh} />
            </Box>
        </Box>

        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'></TableCell>
                                <TableCell className='table-header'>Origen</TableCell>
                                <TableCell className='table-header'>Tipo</TableCell>
                                <TableCell className='table-header'>Identificador</TableCell>
                                <TableCell className='table-header'>Fecha de recepción</TableCell>
                                <TableCell className='table-header'>Observaciones</TableCell>
                                {item.etapaProcesal && <TableCell className='table-header'>Acciones</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(documentosData ?? [])
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((documento) => {
                                    const isItemSelected = isSelected(documento.id);
                                    return (
                                        <StyledTableRow key={documento.id} hover style={{ cursor: 'pointer' }}
                                            selected={isItemSelected} className={documento.tipoCarpeta === "PIEZA" && (documento.estatus === "CANCELADO" || documento.estatus === "INTEGRADO" || isConsulta)?'disabled':''}
                                            onClick={() => {
                                                if (documento.tipo === "AMPARO") {
                                                    navigate('/api/workflow/amparo', {
                                                        state: { documentoId: documento.id, isEdit: true, expediente: item.expediente}
                                                    });
                                                }
                                            }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    disabled={
                                                        documento.tipoCarpeta === "PIEZA" || !item.etapaProcesal || isConsulta
                                                    }
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }
                                                    }
                                                    onChange={(e) => {
                                                        handleSelectRow(documento.id)
                                                    }
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>{documento.origen}</TableCell>
                                            <TableCell>{documento.tipo}</TableCell>
                                            <TableCell>{documento.identificador}</TableCell>
                                            <TableCell>{new Date(documento.fechaRegistro).toLocaleString()}</TableCell>
                                            <TableCell>{documento.observaciones  || documento.estatus}</TableCell>
                                            {item.etapaProcesal && (
                                            <TableCell>

                                                {(documento.ruta || (documento.tipo === 'OFICIO' || documento.tipo === 'ACUERDO')) && (
                                                    <PictureAsPdfIcon onClick={()=>handlePDF(documento)} />
                                                )}

                                                {(documento.tipoCarpeta !== 'PIEZA' && !isConsulta) && (
                                                    <DriveFolderUploadIcon 
                                                        onClick={() => {
                                                            handlePieza(documento.id);
                                                        }} />
                                                )}

                                                {(documento.tipo === 'OFICIO' && !isConsulta) && (
                                                    <DoNotDisturbAltIcon />
                                                )}

                                            </TableCell>
                                            )}
                                        </StyledTableRow>);
                                })}
                            {documentosData.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay registros disponibles.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Modal</DialogTitle>
                <DialogContent>
                    CONTENIDO POR CONFIRMAR
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>

            <ModalPieza
                open={openModalPieza}
                onClose={handleCloseModal}
                data={modalDataPieza}
                handleRefresh={handleRefresh}
            />
        </Box>
    </>)
}

export default Documentos;
