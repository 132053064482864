import { Grid, Tab, Tabs, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Acciones from "../../../components/Acciones/Acciones";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import General from "./Tabs/General/General";
import Historico from "./Tabs/Historico";
import Documentos from "./Tabs/Documentos";
import Acuerdos from "./Tabs/Acuerdos";
import Audiencias from "./Tabs/Audiencias";
const Asignado = () => {
    const location = useLocation();
    const tabnav = location.state?.tabnav || 0;
    const isConsulta = location.state?.isConsulta || false;
    
    const [tabvalue, setTabValue] = useState(tabnav);
    const navigate = useNavigate();

    const accion2 = () => {
        navigate('/api/bandeja/asignados');
    };

    // Cambio de tab
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // Tooltips específicos para el tab
    const toolTips = () => {
        if (tabvalue === 3) {
            return (
                <Tooltip title="Audiencia: Acto procesal en el que las partes exponen sus argumentos ante el juez o tribunal para que este tome una decisión sobre el caso.">
                    <InfoIcon color="action" />
                </Tooltip>
            );
        }
        return null;
    };

    return (
        <>
            <Acciones accion2={accion2} toolTip={toolTips} isDisabled={isConsulta} isVisibleBtn1={false} />

            <Grid item xs={12} sx={{ marginBottom: 4 }}>
                <Tabs value={tabvalue} onChange={handleTabChange} variant="fullWidth">
                    <Tab label="General" className="tab-lowercase" />
                    <Tab label="Documentos" className="tab-lowercase" />
                    <Tab label="Acuerdos y sentencias" className="tab-lowercase" />
                    <Tab label="Audiencias" className="tab-lowercase" />
                    <Tab label="Histórico" className="tab-lowercase" />
                </Tabs>
            </Grid>

            {tabvalue === 0 && (<General isConsulta={isConsulta} />)}
            {tabvalue === 1 && (<Documentos isConsulta={isConsulta} />)}
            {tabvalue === 2 && (<Acuerdos isConsulta={isConsulta} />)}
            {tabvalue === 3 && (<Audiencias isConsulta={isConsulta} />)}
            {tabvalue === 4 && (<Historico />)}
        </>
    );
};

export default Asignado;
