import React, { useState, useEffect, useContext } from 'react';
import { FormControl, Grid, Button, TextField, Autocomplete } from '@mui/material';
import keycloakConf from '../../api/keycloakConf';
import { useNavigate } from 'react-router-dom';
import { fetchJuzgadosAutocomplete } from '../../shared/services/JuzgadosService';
import { fetchJuzgadoActual } from '../../shared/services/JuzgadosService';
import Acciones from '../../components/Acciones/Acciones';

const FormExpedienteJuzgado = ({ handleSubmit, handleValidate, formData, setFormData, errorMessages, irAruta, isDisabled, showJuzgado=true, isEditAmparo}) => {
  const navigate = useNavigate();
  const [juzgados, setJuzgados] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState('');
  const { getToken } = useContext(keycloakConf);
  const mostrarActual = irAruta ? true:false;
  
  useEffect(() => { 
    loadJuzgados();

    const interval = setInterval(() => {
      const now = new Date();
      setCurrentDateTime(now.toLocaleString());
    }, 1000);

    return () => clearInterval(interval);
  }, [getToken]);
  
  const loadJuzgados = async () => {
    try {
      const token = getToken();
      const juzgados = mostrarActual ? await fetchJuzgadoActual(token) : await fetchJuzgadosAutocomplete(token, 0, 20);
      setJuzgados(juzgados || []);

      if (mostrarActual){
        setFormData(prevFormData => ({
          ...prevFormData,
          juzgado: juzgados.pop() 
        }));
      }
    } catch (error) {
      console.log("Error al obtener los Juzgados");
    }
  };

  const handleJuzgadoChange = (event, newValue) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      juzgado: newValue 
    }));
  };

  const handleCancel = () => {

    const ruta = irAruta?irAruta:'/api/bandeja/entrada';
    navigate(ruta);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const [date, time] = currentDateTime.split(', ');

   useEffect(() => {
    if(isEditAmparo){
      if (
        formData?.expediente?.trim() &&
        formData?.year?.trim() &&
        formData?.juzgado &&
        !isDisabled
      ) {
        handleValidate();
      }
    }
  }, [isEditAmparo, handleValidate]);

  return (
    <form>
      <Acciones textoBoton1={"Publicar"} accion1={handleSubmit} accion2={ handleCancel }/>

      <Grid container spacing={2} sx={{ marginTop: 1 }} className='box-sombra'>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="expediente"
              label="Expediente"
              variant="outlined"
              fullWidth
              value={formData?.expediente || ''}
              onChange={handleChange}
              error={!!errorMessages.expediente}
              helperText={errorMessages.expediente}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9a-zA-Z]/g, '');  // Solo permite números
              }}
              disabled={isDisabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="year"
              label="Año"
              variant="outlined"
              fullWidth
              value={formData?.year || ''}
              onChange={handleChange}
              error={!!errorMessages.year}
              helperText={errorMessages.year}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');  // Solo permite números
              }}
              inputProps={{ maxLength: 4 }}
              disabled={isDisabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          {showJuzgado && (
            <Autocomplete
              options={juzgados.sort((a, b) => a.materia.localeCompare(b.materia))}
              getOptionLabel={(option) => option.nombre || ''}
              groupBy={(option) => option.materia}
              value={formData?.juzgado || null}
              onChange={handleJuzgadoChange}
              disabled={mostrarActual}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Juzgado"
                  variant="outlined"
                  error={!!errorMessages.juzgado}
                  helperText={errorMessages.juzgado}
                />
              )}
            />
          )}
        </Grid>
        {!isDisabled && (
        <Grid container spacing={2} sx={{ marginTop: 2,  marginBottom: 6}}>
          <Grid item xs={12}>
              <Button
                className='button-validation'
                variant="contained"
                color="primary"
                onClick={handleValidate}
              >
                Validar
              </Button>
          </Grid>
        </Grid>
      )}
      </Grid>

    </form>
  );
};

export default FormExpedienteJuzgado;
