import React from 'react';
import { Grid, TextField } from '@mui/material';

const Demandado = ({
  demandadoTab,
  demandadoFisica,
  demandadoMoral,
  handleInputChange,
  setDemandadoFisica,
  setDemandadoMoral,
  errors,
  isEditable,
  materia
}) => {
  return (
    <Grid container spacing={2} mt={1}>
      <Grid sm={1}></Grid>
      <Grid ml={7} item xs={10}>
        {demandadoTab === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Nombre(s)"
                name="nombreDemandado"
                fullWidth
                value={demandadoFisica.nombreDemandado}
                onChange={(e) =>
                  handleInputChange(e, setDemandadoFisica, 'nombreDemandado')
                }
                error={!!errors.nombreDemandado}
                helperText={errors.nombreDemandado}
                disabled={isEditable}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="Apellido paterno"
                name="apellidoPaternoDemandado"
                fullWidth
                value={demandadoFisica.apellidoPaternoDemandado}
                onChange={(e) =>
                  handleInputChange(e, setDemandadoFisica, 'apellidoPaternoDemandado')
                }
                error={!!errors.apellidoPaternoDemandado}
                helperText={errors.apellidoPaternoDemandado}
                disabled={isEditable}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="Apellido materno"
                name="apellidoMaternoDemandado"
                fullWidth
                value={demandadoFisica.apellidoMaternoDemandado}
                onChange={(e) =>
                  handleInputChange(e, setDemandadoFisica, 'apellidoMaternoDemandado')
                }
                disabled={isEditable}
              />
            </Grid>
            {materia === "Penal" && (
              <Grid item xs={4}>
                <TextField
                  label="Pseudónimo"
                  name="pseudonimoDemandado"
                  fullWidth
                  value={demandadoFisica.pseudonimoDemandado}
                  onChange={(e) =>
                    handleInputChange(e, setDemandadoFisica, 'pseudonimoDemandado')
                  }
                  disabled={isEditable}
                />
              </Grid>
            )}
          </Grid>
        )}

        {demandadoTab === 1 && (
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              name="nombreDemandadoMoral"
              fullWidth
              value={demandadoMoral.nombreDemandadoMoral}
              onChange={(e) =>
                handleInputChange(e, setDemandadoMoral, 'nombreDemandadoMoral')
              }
              error={!!errors.nombreDemandadoMoral}
              helperText={errors.nombreDemandadoMoral}
              disabled={isEditable}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Demandado;
