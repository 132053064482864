import React, { useEffect, useState, useContext } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import GenericModal from '../../../components/Modal/Modal';
import KeycloakConf from '../../../api/keycloakConf';
import { fetchListaEstrado } from '../BandejaService';
import ListaEstradoTable from "../ListaEstrados/ListaEstradoTable";
import SearchInput from "../../../components/Search/SearchInput"
import { getListaEstrado } from '../../../shared/services/ListaEstradoService';

const ListaEstrado = () => {
  const [listaEstrado, setListaEstrado] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [pageable, setPageable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { getToken } = useContext(KeycloakConf);

  useEffect(() => {
    loadListaEstrado();
  }, [getToken, page, rowsPerPage]);

  const loadListaEstrado = async (query = "") => {
    setLoading(true);
    const token = getToken();
    try {
      const data = await fetchListaEstrado(token, page, rowsPerPage, "", query);  // query es el término de búsqueda
      setListaEstrado(data.content);
      setPageable(data.page);
    } catch (error) {
      console.error('Error cargando listaEstrado:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnListaEstrado = async (onListaEstrado) => {
    const token = getToken();
    let pdf = await getListaEstrado(token, onListaEstrado);
    var blob = new Blob([pdf], { type: "application/pdf" });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.target = "ReporteListaEstrado.pdf";
    link.click();
  };

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          &nbsp;
        </Box>
        <Box flexBasis="300px" ml={2}>
          <SearchInput
           value={searchQuery}
           onChange={(e) => {
            setSearchQuery(e.target.value);
            loadListaEstrado(e.target.value); 
          }}
            placeholder="Buscar..."
            ariaLabel="Campo de busqueda para instituciones"
            tooltipTitle="Escriba para buscar"
          />
        </Box>
      </Box>
      <Box>
        <GenericModal>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="CURP"
                variant="outlined"
                fullWidth
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button className='button-validation' type="button" variant="contained" color="success">
                Validar
              </Button>
            </Grid>
          </Grid>
        </GenericModal>
        
        {/* Mostrar "Cargando..." solo cuando el estado loading es verdadero */}
        {loading ? <p>Cargando...</p> : (
          <ListaEstradoTable
            listaEstrado={listaEstrado}
            pageable={pageable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            onListaEstrado={handleOnListaEstrado}
          />
        )}
      </Box>
    </>
  );
};

export default ListaEstrado;
