import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    TextField,
    Checkbox
} from '@mui/material';
import { create, fetchById, update } from '../BloqueService';
import { validateFields } from '../../../components/Alert/ValidationRequired/validationRequired';
import { useNavigate } from 'react-router-dom';
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertUpdate } from '../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import { useToastAlertValidation } from '../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import AccionesOnlyButtons from '../../../components/Acciones/AccionesOnlyButtons';

const FormBloque = ({token, onAdd, onEdit}) => {
    const navigate = useNavigate();
    const { showSnackbar, showConfirmDialog } = useToastAlert(); 
    const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const [formData, setFormData] = useState({
        id: '',
        horaInicial: '',
        horaFinal: '',
        estado: ''
    });
    const [errorMessages, setErrorMessages] = useState({});

    useEffect(() => {
        if (onEdit) {
            getById();
        } else {
            resetForm();
            setFormData((prevData) => ({
                ...prevData,
                estado: 'ACTIVE',
            }))
        }
    }, [onEdit, token]);

    const resetForm = () => {
        setFormData({
            id: '',
            horaInicial: '01:00',
            horaFinal: '01:00',
            estado: 'ACTIVE'
        });
        setErrorMessages({});
    };

    const getById = function (id) {
        const loadbyId = async () => {
            try {
                const bloque = await fetchById(token, onEdit.id);
                console.log(bloque);
                setFormData(bloque);
            } catch (error) {
                console.error('Error al obtener el bloque:', error);
            }
        };
        loadbyId();
    };


    const handleChange = (e) => {
        const {name, value} = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setErrorMessages({
            ...errorMessages,
            [name]: '',
        });
    };

    const handleSubmit = async (e) => {
        const rules = {
            horaInicial: {required: true},
            horaFinal: {required: true},
            estado: {required: true}
        };

        const errors = validateFields(formData, rules, showAlertValidate)

        if (Object.keys(errors).length > 0) {
            setErrorMessages(errors);
            return;
        }

        let formDataToSubmit = {
            ...formData,
            audit: {}
        };

        try {
            if (formData.id) {
                await update(token, formDataToSubmit);
                showUpdateSnackbar('Registro actualizado con éxito', 'success');
            } else {
                await create(token, formDataToSubmit);
                showSnackbar('Registro guardado con éxito', 'success');
            }

            if (onAdd) {
                onAdd();
            }

            resetForm();
        } catch (error) {
            console.error('Error al guardar el registro:', error);
            showSnackbar('¡Algo salió mal!', 'error');
        }
    };

    const handleCancel = () => {
        navigate('/api/core/bloques');
    };

    return (
        <form>
            <AccionesOnlyButtons accion1={handleSubmit} accion2={handleCancel}/>
            <Grid container spacing={2} sx={{marginTop: 1}}  className='box-sombra'>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        type="time"
                        name="horaInicial"
                        label="Hora Inicial"
                        variant="outlined"
                        fullWidth
                        value={formData.horaInicial}
                        onChange={handleChange}
                        error={!!errorMessages.horaInicial}
                        InputLabelProps={{
                            shrink: true, 
                        }}
                    />
                    {errorMessages.horaInicial && <FormHelperText error>{errorMessages.horaInicial}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        type="time"
                        name="horaFinal"
                        label="Hora Final"
                        variant="outlined"
                        fullWidth
                        value={formData.horaFinal}
                        onChange={handleChange}
                        error={!!errorMessages.horaFinal}
                        InputLabelProps={{
                            shrink: true, 
                        }}
                    />
                    {errorMessages.horaFinal && <FormHelperText error>{errorMessages.horaFinal}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined">
                        <FormControlLabel
                        control={
                            <Checkbox
                            checked={formData.estado === "ACTIVE"}
                            onChange={(event) => handleChange({
                                target: {
                                name: 'estado',
                                value: event.target.checked ? 'ACTIVE' : 'INACTIVE'
                                }
                            })}
                            color="primary"
                            />
                        }
                        label="Activar bloque"
                        />
                        {errorMessages.estado && <FormHelperText>{errorMessages.estado}</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    );
}

export default FormBloque; 