import React, { useState, useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import { RouterProvider } from 'react-router-dom';

import Spinner from './components/Spinner/Spinner';
import router from './components/Routes';
import axiosInstance, { setSpinnerCallback } from './api/axiosConfig';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button, Box, Checkbox, Tabs, Tab } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#636569', // Gris oficial
    },
    secondary: {
      main: '#636569', // Gris oficial
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#636569', // Gris oficial
            borderWidth: '2px',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#95b74b', // Color activo
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#95b74b', // Color de la etiqueta cuando está enfocado
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // Bordes redondeados
          padding: '4px', // Espaciado entre los bordes y las tabs
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          border: '2px solid #636569', // Borde gris oficial alrededor de las tabs
          marginRight: '5px',
          color: '#636569', // Gris para tabs inactivos
          fontWeight: 'normal', // Estilo de fuente normal para tabs inactivas
          padding: '6px 12px', // Espaciado para las pestañas
          borderRadius: '4px', // Bordes redondeados para cada tab
          '&.Mui-selected': {
            color: 'rgb(140, 146, 188)', // Color morado para tabs activas
            fontWeight: 800, // Negrita para el tab activo
            borderColor: 'rgb(140, 146, 188)', // Borde morado para el tab activo
          },
          '&:hover': {
            backgroundColor: '#e0e0e0', // Fondo claro cuando el tab no está seleccionado y se pasa el cursor
            color: '#636569', // Color gris para hover
          },
        },
      },
    },TableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgb(99, 101, 105)', // Hover en filas de tabla
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderColor: '#636569', // Gris oficial
          borderWidth: '2px',
          backgroundColor: 'transparent',
          color: '#636569',
          '&:hover': {
            backgroundColor: '#4e5153',
            borderColor: '#4e5153',
            color: '#fff',
          },
          '&:focus': {
            backgroundColor: 'transparent',
            borderColor: '#95b74b',
            color: '#636569',
          },
        },
      },
    },
    MuiTransferList: {
      styleOverrides: {
        root: {
          boxShadow: '0px 8px 20px 0px hsl(0deg 0% 0% / 30%)',
        },
        button: {
          backgroundColor: '#636569',
          borderColor: '#636569',
          borderWidth: '2px',
          '&:hover': {
            backgroundColor: '#4e5153',
            borderColor: '#4e5153',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 12px 0px hsl(0deg 0% 0% / 18%)',
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 12px 0px hsl(0deg 0% 0% / 18%)',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#95b74b', // Gris oficial para los checkboxes seleccionados
          },
          '&:hover': {
            backgroundColor: 'rgba(99, 101, 105, 0.1)',
          },
        },
      },
    },
  },
});

const App = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSpinnerCallback(setLoading); // Spinner con Axios
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Spinner open={loading} /> {/* Spinner global */}
          <RouterProvider router={router} />
        </CssBaseline>
      </ThemeProvider>
    </>
  );
};

export default App;
