import axios from 'axios';


export const getTipoAudiencias = async(token) =>{
 
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/tipoaudiencia`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      
      return response.data.content;
  
    } catch (error) {
      console.log('Error al obtener los tipos de audiencias del documento: ', error);

    }
  
  }

  export const getSalasAll = async(token) =>{
 
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/salas/allByJuzgado`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      
      return response.data;
  
    } catch (error) {
      console.log('Error al obtener las salas: ', error);

      
    }
  
  }

  export const createAudiencia = async(token, data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + "/api/workflow/audiencias/crearAudiencias", data, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });
      return response.data;
  } catch (error) {
      console.error('Error al crear la audiencia:', error);
      throw error;
  }
  }

  export const reprogramarAudiencia = async (token, data) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/bandeja/audienciasgenerales/reprogramar`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
      });
      return response.data;
    } catch (error) {
      console.error('Error al reprogramar la audiencia:', error);
      throw error;
    }
  };