import {
    Tooltip
} from "@mui/material";

import DevolverIcon from '@mui/icons-material/Replay';
import SendIcon from '@mui/icons-material/Send';
import PublishIcon from '@mui/icons-material/Publish';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import ObservacionesIcon from '@mui/icons-material/Feedback';
import { acoplarPieza } from "../../../shared/services/CarpetaService";
import { useState } from "react";
import ReturnTurnadoModal from "../../../components/Modal/ReturnTurnadoModal";

const ButtonsAccionRapida = ({
    item,
    handleSingleTurnar,
    handleOpenModal,
    handleCloseModal,
    handleConfirmPieza,
    setMessageDialogPieza,
    setTitleDialogConfirm,
    setButtonCancelLabel,
    setButtonOkLabel,
    handleRefresh,
    isCarpetaAsignadaPromocion
}) => {

    const [selectedExpediente, setSelectedExpediente] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const devolverExpediente = (id, expediente) => {
        setSelectedExpediente({ expediente, documentoId: id});
        setIsModalOpen(true);
    };

    const handleCloseModalInterno = () => {
        setIsModalOpen(false);
        setSelectedExpediente(null);
    };

    const handleSave = (data) => {
        handleCloseModalInterno();
    };
    const publicarAcuerdo = (id) => {
        console.log(id);
    };

    const verNotificaciones = (id) => {
        console.log(id);
    };

    const handleAdjuntarPieza = (item) => {
        const data = {
            carpetaId: item.carpetaId,
            expediente: item.expediente,
            tipo: item.tipoEntrada,
            carpetaAsignada: isCarpetaAsignadaPromocion(item.carpetaId),
            documentos: [{ id: item.id, expediente: item.expediente },]
        };
        handleOpenModal(data, "pieza");
    };

    const handleAcoplarPieza = (item, accion) => {
        const data = {
            piezaId: item.carpetaId,
            estatus: accion === "CANCELAR" ? "CANCELADO" : accion === "INTEGRAR" ? "INTEGRADO" : ""
        }

        if (accion === "CANCELAR") {
            setMessageDialogPieza(`Se cancelará la Pieza ${item.expediente} de manera permanente`);
            setTitleDialogConfirm("¿Cancelar Pieza?");
            setButtonOkLabel("Si, cancelar");
            setButtonCancelLabel("No, salir");
        }

        if (accion === "INTEGRAR") {
            setTitleDialogConfirm("¿Integrar Pieza?");
            setMessageDialogPieza(`Se integrará la Pieza ${item.expediente} al expediente principal`);
            setButtonOkLabel("Si, integrar");
            setButtonCancelLabel("No, cancelar");
        }

        handleOpenModal(data, "confirm");

    };

    return (
        <>
            {item.estatus === "Turnado" &&
                <Tooltip title="Cancelar turnado">
                    <DevolverIcon
                      style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={(e) => {
                            e.stopPropagation();
                            devolverExpediente(item.id, item.expediente);
                        }}
                    />
                </Tooltip>
            }
            {/*{item.estatus !== "Turnado" &&
                <Tooltip title={item.observaciones}>
                    <ObservacionesIcon
                      style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={() =>
                            null
                        }
                    />
                </Tooltip>
            }
            */}
            {/*
            {item.estatus !== "Turnado" && item.tipoEntrada !== "Promocion" &&

                <Tooltip title="Publicar">
                    <PublishIcon
                      style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={(e) => {
                            e.stopPropagation();
                            publicarAcuerdo(item.id);
                        }}
                    />
                </Tooltip>
            }
            */}
            {/*
            {item.estatus !== "Turnado" && item.tipoEntrada !== "Promocion" &&
                <Tooltip title="Notificaciones">
                    <NotificationsIcon
                      style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={(e) => {
                            e.stopPropagation();
                            verNotificaciones(item.id);
                        }}
                    />
                </Tooltip>
            }
            */}
            {item.estatus !== "Turnado" && item.tipoEntrada !== "Promocion" &&
                <Tooltip title="Turnar">
                    <SendIcon
                      style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleSingleTurnar(item.id)
                        }}
                    />
                </Tooltip>
            }

            {item.estatus !== "Turnado" && item.tipoEntrada === "Promocion" &&
                <Tooltip title="Adjuntar">
                    <DriveFolderUploadIcon
                      style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAdjuntarPieza(item);
                        }}
                    />
                </Tooltip>
            }

            {item.tipoEntrada === "Pieza" &&
                <Tooltip title="Integrar Pieza">
                    <DriveFolderUploadIcon
                    
                        className="icon-color"
                        style={{ transform: 'rotate(180deg)', marginRight: '10px' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAcoplarPieza(item, "INTEGRAR");
                        }}
                    />
                </Tooltip>
            }
            {item.tipoEntrada === "Pieza" &&
                <Tooltip title="Cancelar Pieza">
                    <DoNotDisturbAltIcon
                      style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAcoplarPieza(item, "CANCELAR");
                        }}
                    />
                </Tooltip>
            }

            <ReturnTurnadoModal
                isOpen={isModalOpen}
                handleClose={handleCloseModalInterno}
                expediente={selectedExpediente}
                handleSave={handleSave}
                handleRefresh={handleRefresh}
            />
        </>
    );
};

export default ButtonsAccionRapida;